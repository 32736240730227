import React from 'react'
import styled from 'styled-components'
import BodyPanelContainer from '../BodyPanelContainer'
import FullCard from './LayoutElements/FullCard';
import TightH1 from '../_Headlines/TightH1'
import Paragraphs from '../_Containers/Paragraphs'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import CustomMarkdown from '../_common/CustomMarkdown';
import DownArrow from '../_common/DownArrow';

const TOCPanelBodyContainer = styled(BodyPanelContainer)`
  height: 155px;
`;

const TOCFullCard = styled(FullCard)`
  /* margin-bottom: 10px; */
  > div {
    padding: 0px;
  }
`

const CustomAnchorLink = styled(AnchorLink)`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  margin-left: 0px;
  p {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-left: 0px;
    padding-left: 0px;
    font-size: ${p => p.textSize ? p.textSize + 'px' : '14px'};
    line-height: ${p => p.textSize ? (p.textSize * 1.2) + 'px' : '143px'};
  }

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: ${p => p.mobileTextSize ? p.mobileTextSize + 'px' : '14px'};
    line-height: ${p => p.mobileTextSize ? (p.mobileTextSize * 0.9) + 'px' : '13px'};
  }

`

const CustomDownArrow = styled(DownArrow)`
  fill: ${p => p.theme.links.default};
  height: ${p => p.textSize ? p.textSize * 1.1 : 18}px;
  width: ${p => p.textSize ? p.textSize * 0.9 : 18}px;
  margin-right: 4px;
  margin-top: 0px;
  margin-bottom: auto;
  margin-left: -2px;

`

const AnchorLinkItem = ({anchorName, linkText, textSize, mobileTextSize, textColor}) => (
  <CustomAnchorLink textColor={textColor} textSize={textSize} mobileTextSize={mobileTextSize} href={`#${anchorName}`}><CustomDownArrow textSize={textSize} mobileTextSize={mobileTextSize}/><CustomMarkdown>{linkText}</CustomMarkdown></CustomAnchorLink>
)

export const TOC = ({config, headline, text, anchorLinks, mobileView}) => {

  let { backgroundColor, mobileBackgroundColor, mobileBorderColor, borderColor, borderWidth, borderRadius } = config;

  return (
    <TOCFullCard mobileBorderColor={mobileBorderColor} borderColor={borderColor} borderWidth={borderWidth} borderRadius={borderRadius} mobileBackgroundColor={mobileBackgroundColor} backgroundColor={backgroundColor} fitContent>
      <TightH1 {...headline}>{headline.text}</TightH1>
      <Paragraphs reduceFootprint fontSize={text.fontSize} mobileFontSize={text.mobileFontSize}>
        { anchorLinks.map(({anchorName, title}, index) => (
          <AnchorLinkItem textColor={text.color} textSize={text.textSize} mobileTextSize={text.mobileTextSize} anchorName={anchorName} linkText={title}/>
        ))}
      </Paragraphs>
    </TOCFullCard>
  )

}

export const TOCPanel = (props) => {

  return (
    <TOCPanelBodyContainer>
      <TOC {...props} />
    </TOCPanelBodyContainer>
  )


}

export default TOCPanel