import React from 'react'
import styled from 'styled-components'
import BodyPanelContainer from '../BodyPanelContainer'
import FullCard from './LayoutElements/FullCard';
import TightH1 from '../_Headlines/TightH1'
import Paragraphs from '../_Containers/Paragraphs'
import CustomMarkdown from '../_common/CustomMarkdown';

const SmallTextBodyContainer = styled(BodyPanelContainer)`
  height: 155px;
`;

const SmallTextFullCard = styled(FullCard)`
  /* margin-bottom: 10px; */
  > div {
    padding: 0px;
  }
`

// const LinkRightIfDesktop = styled(Link)`
//   width: fit-content;
//   margin-right: 0px;
//   margin-left: auto;
//   @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
//     font-size: 18px;
//     line-height: 18px;
//     margin-right: auto;
//     /* padding: 5px; */
//     margin-right: auto;
//     margin-left: -2px;
//   }
  

// `

export const SmallText = ({config, headline, text, button, mobileView}) => {

  let { backgroundColor, mobileBorderColor, mobileBackgroundColor, borderColor, borderWidth, borderRadius } = config;

  return (
    <SmallTextFullCard mobileBorderColor={mobileBorderColor} borderColor={borderColor} borderWidth={borderWidth} borderRadius={borderRadius} mobileBackgroundColor={mobileBackgroundColor} backgroundColor={backgroundColor} fitContent>
      <TightH1 {...headline}>{headline.text}</TightH1>
      <Paragraphs reduceFootprint noBottomMargin fontSize={text.fontSize} mobileFontSize={text.mobileFontSize}>
        <CustomMarkdown>{text.text.length > 1 ? text.text.map(text => `${text}  \n`).join('') : text.text[0]}</CustomMarkdown>
      </Paragraphs>
    </SmallTextFullCard>
  )

}

export const SmallTextPanel = (props) => {

  return (
    <SmallTextBodyContainer>
      <SmallText {...props} />
    </SmallTextBodyContainer>
  )


}

export default SmallTextPanel