import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import BodyPanelContainer from '../BodyPanelContainer'
import { AccordionDownButton } from '../_common'
import Headline from './Headline'


const IssuesAccordionBodyContainer = styled(BodyPanelContainer)`
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 30px;
  padding-top: 0px;
`

const IssuesAccordionSectionBody = styled.div`
  margin-bottom: 20px;
  box-shadow: lightgray -3px 3px;

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  transition: 0.2s linear;
  &:last-of-type {
    margin-bottom: 0px;
  }
`



// const IssuesBriefLinkDescription = styled.div`
//   text-align: center;
//   margin-left: auto;
//   margin-right: auto;
//   color: white;
// `

const IssuesBriefAccordionDownButton = styled(AccordionDownButton)`
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: ${p => p.rotate ? '5px' : '15px'};
  padding-top: ${p => p.rotate ? '15px' : '5px'};
  display: block;
  enable-background: new 0 0 980 224;
  fill: ${p => p.theme.colors.jiarEggshell};
  ${p => p.rotate ? `
    transform: rotate(180deg);
    transition: 0.2s linear;
  ` : `
    transform: rotate(0deg);
    transition: 0.2s linear;
  `}
`;

const IssuesAccordionHeadlineToggleBar = styled.div`
  background-color: ${p => p.theme.colors[p.backgroundColor]};
  padding-left: 10px;
  padding-right: 10px;

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  ${p => p.expand ? `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  
  ` : ``}

  div {
    h2 {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 5px;
    }
  }
`



const IssuesAccordionSectionInnerContainer = styled.div`
  padding: ${p => p.show ? '10px' : '0px'};
  background-color: ${props => props.theme.colors.jiarEggshell};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height:  ${p => p.show ? 'fit-content' : '0px'};
  overflow: hidden;
`

const IssueFullPanelListItemsContainer = styled.div`

  ul {
    padding-left: 20px;
  }

`

const IssueFullPanelListItem = styled.li`
`;

const IssueFullPanelListItemMarkdownSpan = styled(ReactMarkdown)`
  > p {
    margin-top: 1px;
    line-height: ${(props) => (props.mobileView ? '20px' : '22px')};
  }
`;

const IssueSectionIntroMarkdownSpan = styled(ReactMarkdown)`

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }

`

const IssuesAccordionHeadline = ({title}) => <Headline h={2} hideTopArrow={true}>{title.toUpperCase()}</Headline>

const IssuesAccordionSection = ({ issue, mobileView }) => {

  let [expand, setExpand] = useState(false)

  return (
    <IssuesAccordionSectionBody id={issue.slug}>
      <IssuesAccordionHeadlineToggleBar onClick={() => setExpand(!expand)} backgroundColor={issue.color} expand={expand}>
          <IssuesAccordionHeadline title={issue.title}/>
          <IssuesBriefAccordionDownButton rotate={expand}/>
      </IssuesAccordionHeadlineToggleBar>
        <IssuesAccordionSectionInnerContainer show={expand}>
          <IssueSectionIntroMarkdownSpan>
            {issue.intro}
          </IssueSectionIntroMarkdownSpan>
          <IssueFullPanelListItemsContainer>
            <ul>
                {issue.listItems.map((listItem) => (
                    <IssueFullPanelListItem key={listItem.substr(0,20)} >
                        <IssueFullPanelListItemMarkdownSpan>
                            {listItem}
                        </IssueFullPanelListItemMarkdownSpan>
                    </IssueFullPanelListItem>
                ))}
            </ul>
          </IssueFullPanelListItemsContainer>
        </IssuesAccordionSectionInnerContainer>
    </IssuesAccordionSectionBody>

  )
}

const IssuesAccordionInnerBodyContainer = styled.div`
  padding-top: 10px;
`

const IssuesAccordion = ({ issues, title, mobileView }) => {

  return (
    <IssuesAccordionBodyContainer>
      <Headline
        hideTopArrow={true}
        mobileView={mobileView}
      >
        {title}
      </Headline>
      <IssuesAccordionInnerBodyContainer>
        { issues.map((issue, i) => (
          <IssuesAccordionSection issue={issue} key={'issuesection'+i}/>
        ))}
      </IssuesAccordionInnerBodyContainer>

      
    </IssuesAccordionBodyContainer>
  )


}

export default IssuesAccordion