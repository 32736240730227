import React, { useState } from 'react';
import styled from 'styled-components';
import Headline from './Headline';

import {
  SubmitSpinner
} from '../HeaderComponents/JoinCampaignWidget';
import { useFirebase } from '../Firebase';
import TightH1 from '../_Headlines/TightH1';
import SquatButton from '../_common/SquatButton';
import Paragraphs from '../_Containers/Paragraphs';
import ReactMarkdown from 'react-markdown';

export const EmailSignupWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    flex-direction: row;
  }
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {
    flex-direction: column;
  }
`;

export const EmailSignupCardContainer = styled.div`
  /* background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'}; */
  width: 100%;
  margin-left: 0;
  justify-content: space-between;

  @media (max-width: 760px) {
    width: 100%;
  }
  @media (min-width: 761px) {
    width: 60%;
  }
`;

export const EmailTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
`

export const EmailHeadline = styled(TightH1)`
  /* color: ${p => p.theme.colors[p.color] || 'black'};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: ${p => p.fontSize}; */
  width: 100%;
  
`

export const EmailSubHeadline = styled(Headline)`
  margin-top: 10px;
  color: ${p => p.theme.colors[p.color] || 'black'};
  text-align: center;
  
  line-height: 1em;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5em;
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 1.3em;
  }
`

export const EmailDateTimeText = styled(Headline)`
  margin-top: 5px;
  color: ${p => p.theme.colors[p.color] || 'black'};
  text-align: center;
  line-height: 1em;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4em;

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 1.22em;
  }

`

export const EmailImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const EmailSignupImage = styled.img`
  max-width: 60%;
  height: auto;
  object-fit: contain;
`

export const EmailLogo = styled.img`
  max-width: 30%;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  
`

export const EmailSignupFormSectionContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  width: 40%;
  padding-left: 20px;
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    width: 100%;
    height: auto;
  }
  position: relative;
`

export const EmailSignupFormDescription = styled.div`

`

export const EmailFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: auto;
  padding-right: 10px;

  @media(max-width: ${p => p.theme.siteConfig.breakpoints.mobile ? p.theme.siteConfig.breakpoints.mobile : 760}px) {
    flex-direction: column;
  }
`

export const EmailFormItemsContainer = styled.div`
  display: flex;
  width: 100%;

  padding-top: 0px;
  padding-left: 0;
  padding-right: 0;
  margin-top: auto;
  flex-direction: row;

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 6px;
    margin-top: 0px;
    flex-direction: row;
    /* > div:fir  Zst-of-type {
      padding-top: 2px;
      > input {
        margin: 0px 3px 3px 3px;
      }

    } */
  }

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {
    margin-top: 10px;
  }
`

export const EmailForm = styled.form`
  font-family: ${(props) => props.theme.fontFamilies.subHeader};
  min-width: 275px;
  font-weight: normal;
  margin-right: auto;
  margin-left: 0;

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    margin-left: auto;
    padding-left: 10px;
    margin-right: 0;
    min-width: 50%;
  }
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {
    margin-left: 0;
    margin-right: auto;
    width: 100%;
    padding-left: 0px;
  }

`

export const EmailFieldContainer = styled.div`
  width: 100%;
  @media(max-width: 760px) {
    padding-bottom: 5px;
    margin-left: 0px;
    margin-right: 10px;
    &:only-child {
      > input {
        width: 100%;
      }
    }
    &:last-child {
      > input {
        width: 100%;
      }
    }
  }

  @media(min-width: 761px) {
    padding-bottom: 5px;
    &:first-child {
      padding-right: 10px;
    }

    &:only-child {
      padding-right: 0px;
      > input {
        width: 100%;
      }
    }
    &:last-child {
      > input {
        width: 100%;
      }
    }
  }
`

export const EmailField = styled.input`
  border-radius: 3px;
  font-family: ${p => p.theme.fontFamilies.paragraph};
  font-stretch: 100%;
  /* font-variant: ${p => p.content.length > 0 && 'small-caps'}; */
  font-weight: normal;
  letter-spacing: -0px;

  color: black;

  border-color: lightgray;
  line-height: 14px;
  width: 90%;
  font-size: 14px;
  padding: 2px 5px;
  margin: 3px 10px 0px 0px;

  @media(max-width: ${p => p.theme.siteConfig.breakpoints.mobile}) {
    border-color: lightgray;
    width: 90%;
    font-size: 14px;
    line-height: 14px;
    padding-left: 5px;


  }

  ::placeholder {
    color: darkgray;
    opacity: 1;

    @media(max-width: 760px) {
      line-height: 18px;
    }

    @media(min-width: 761px) {
      line-height: 14px;
    }

  }

`

export const EmailFieldLabel = styled.label`
display: block;
  padding-left: 4px;
  margin-top: 0px;
  padding-top: 4px;
  font-family: ${p => p.theme.fontFamilies.paragraph};
  font-size: 12px;
  line-height: 12px;
  @media(max-width:${p => p.theme.siteConfig.breakpoints.mobile}px) {
    padding-top: 2px;
    font-size: 12px;
    line-height: 12px;
  }

`

export const EmailSubmitButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: inline-flex;
  margin-top: 5px;
  margin-left: -1px;
 
  @media(max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    margin-top: 5px;
  }
`

export const EmailSubmitButton = styled.button`
  font-family: ${(props) => props.theme.fontFamilies.header};
  border-radius: 3px;
  margin-left: auto;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer !important;
  letter-spacing: -0.5px;

  @media(max-width: 760px) {
    float: left;
    height: 20px;
    font-size: 16px;
    line-height: 16px;
    border: solid ${p => p.theme.links.default} 1px;
    width: 80px;
    margin-right: auto;  
  }

  @media(min-width: 761px) {
    float: right;
    height: 30px;
    font-size: 22px;
    line-height: 22px;
    border: solid ${p => p.theme.links.default} 1px;
    width: 100px;
  }
`

export const Required = styled.span`
    color: red;
`

export const RequiredIndicator = () => (<Required>*</Required>)

export const RequiredKeyContainer = styled.p`
  margin-top: 0px;
  margin-block-start: 0px;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: auto;
  @media(max-width: 760px) {
    font-size: 12px;
  }

  @media(min-width: 761px) {
    font-size: 12px;
  }
`

export const RequiredKey = ({text}) => (<RequiredKeyContainer><Required>*</Required><span> = {text}</span></RequiredKeyContainer>)

export const SimpleEmailSignupWidget = ({
  emailLabel,
  signupFormHeadlineText,
  signupFormDescription,
  submitText,
  submitSuccessText,
  submitErrorText,
  signupFormRequired,
  fontSize,
  mobileFontSize,
  mobileView,
}) => {

  const [emailState, setEmailState] = useState('');

  const [submitError, setSubmitError] = useState(false);

  const { firebase } = useFirebase();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleTextInput(e, field) {
    setSuccess(false);
    switch (field) {
      case 'email':
        setEmailState(e.target.value);
        break;
      default:
        break;
    }
  }

  
  function clearFormValues() {
    setEmailState('');
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (success) {
      clearFormValues();
      setSuccess(false);
    } else {
      setLoading(true);
      firebase
        .submitSimpleEmailSignup({
          email: emailState
        })
        .then((response, error) => {
          if (!error) {
            registerSuccess();
          } else {
            registerError();
          }
          //   console.log(response);
        });
    }
  }

  function registerSuccess() {
    setSuccess(true);
    setLoading(false);
  }

  function registerError() {
    setSuccess(false);
    setLoading(false);
    setSubmitError(true);
  }


  return (
    <EmailSignupWidgetContainer>

        <EmailSignupFormDescription>
          <EmailHeadline hideTopArrow={true}>
            {signupFormHeadlineText}
          </EmailHeadline>
          <Paragraphs reduceFootprint fontSize={fontSize} mobileFontSize={mobileFontSize}>
            <ReactMarkdown>{signupFormDescription}</ReactMarkdown>
          </Paragraphs>
        </EmailSignupFormDescription>
        <EmailForm onSubmit={handleSubmit}>
          <EmailFormContainer>
            <EmailFormItemsContainer>
              <EmailFieldContainer 
                placeholder={emailLabel}
                key={'email'}
              >
                <EmailField 
                  content={emailState}
                  onChange={e => handleTextInput(e, 'email')}
                  type="text"
                  // placeholder={emailLabel}
                  key={'email'}
                />
                <EmailFieldLabel>
                  {emailLabel}
                </EmailFieldLabel>
              </EmailFieldContainer>
            </EmailFormItemsContainer>
          </EmailFormContainer>
          <EmailSubmitButtonContainer>
            <SquatButton>
              {!loading && !success && !submitError && submitText}
              {loading && <SubmitSpinner/>}
              {success && submitSuccessText }
              {submitError && submitErrorText }
            </SquatButton>
          </EmailSubmitButtonContainer>
        </EmailForm>
    </EmailSignupWidgetContainer>
  )
};


export default SimpleEmailSignupWidget