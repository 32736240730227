const firebaseConfig = {
  apiKey: 'AIzaSyCtqxSI_bip_ANlwzy0KKCYnTJsR3TQ91k',
  authDomain: 'nursefornycc.firebaseapp.com',
  databaseURL: 'https://nursefornycc.firebaseio.com',
  projectId: 'nursefornycc',
  storageBucket: 'nursefornycc.appspot.com',
  messagingSenderId: '783633688746',
  appId: '1:783633688746:web:bd796b3a8b7121a3dd88d3',
  measurementId: 'G-ZVCGNQPSYP',
};

export default firebaseConfig;
