import React from 'react'
import styled from 'styled-components'
import { ResourceContainer } from "."
import {DownloadIcon} from '../../_common'

export const FileResourceIcon = styled(DownloadIcon)`
height: 20px;
margin-top: auto;
margin-bottom: -2px;
margin-right: 5px;
fill: ${p => p.theme.colors.jiarSalmon};
`

const FileResource = ({ title, fileName, className }) => (
  <ResourceContainer className={className}>
    <a href={`pdfs/${fileName}`} download>
      <FileResourceIcon />
      <span>{title}</span>
    </a>
  </ResourceContainer>
);

export default FileResource