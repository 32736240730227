import React from 'react'
import styled from 'styled-components'
import Headline from './Headline'

const LinkListContainer = styled.div`
    padding: 17px;
`

const LinkA = styled.a`
`

const LinkListItem = styled.li`
`


const LinkUL = styled.ul`
`

const LinkList = ({title, links}) => {
    return (
        <LinkListContainer>
            <Headline>{title}</Headline>
            <LinkUL>
                { links.map((link) => (
                    <LinkListItem>
                        <LinkA href={link.url}>{link.text}</LinkA>
                    </LinkListItem>
                ))}
            </LinkUL>
        </LinkListContainer>
    )
}

export default LinkList