import styled from 'styled-components'

export const ThirdsCard = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  width: 33%;
  min-height: 100%;
  margin-right: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:last-of-type{
    margin-right: 0px;
  }
`

export default ThirdsCard