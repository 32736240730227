import TextPanel from './TextPanel';
import EndorsementsList from './EndorsementsList';
import Gallery from './Gallery';
import StoryGallery from './StoryGallery';
import Map from './Map';
import SignupWidget from './SignupWidget';
import Headline from './Headline';
import FullWidthImage from './FullWidthImage';
import LinkList from './LinkList';
import IssuesPanel from './IssuesPanel';
import VideoPlayer from './VideoPlayer';
import EndorsementsGallery from './EndorsementsGallery';
import MeetSandySignupWidget from './MeetSandySignupWidget';
import IssueBriefBoxesPanel from './IssueBriefBoxesPanel';
import IssueFullPanel from './IssueFullPanel';
import IntroPanelWithInsetGallery from './IntroPanelWithInsetGallery';
import IssuesAccordion from './IssuesAccordion';
import MobilizeEventsList from './MobilizeEventsList';
import GalleryLauncher from './GalleryLauncher';
import GalleryLaunchBanner from './GalleryLaunchBanner';
import ResourcesSection from './ResourcesSection';
import HighlightedPagePanel, {HighlightedPage} from './HighlightedPagePanel';
import SmallTextPanel, {SmallText} from './SmallTextPanel';
import { FullCard, HalvesCard, PanelRow, PanelRowWithChildren, ThirdsCard, FullCardWithChildren } from './LayoutElements'
import BigLogo from './BigLogo'
import BodyPanel from './BodyPanel'
import SimpleEmailSignupWidget from './SimpleEmailSignupWidget'
import { TOC, TOCPanel } from './TOCPanel'

export default {
  TextPanel: TextPanel,
  EndorsementsList: EndorsementsList,
  Gallery: Gallery,
  Map: Map,
  SignupWidget: SignupWidget,
  Headline: Headline,
  FullWidthImage: FullWidthImage,
  LinkList: LinkList,
  IssuesPanel: IssuesPanel,
  VideoPlayer: VideoPlayer,
  StoryGallery: StoryGallery,
  EndorsementsGallery: EndorsementsGallery,
  MeetSandySignupWidget: MeetSandySignupWidget,
  IssueBriefBoxesPanel: IssueBriefBoxesPanel,
  IssueFullPanel: IssueFullPanel,
  IntroPanelWithInsetGallery: IntroPanelWithInsetGallery,
  IssuesAccordion: IssuesAccordion,
  MobilizeEventsList: MobilizeEventsList,
  GalleryLauncher: GalleryLauncher,
  GalleryLaunchBanner: GalleryLaunchBanner,
  ResourcesSection: ResourcesSection,
  HighlightedPage,
  SmallText,
  SmallTextPanel,
  HighlightedPagePanel,
  FullCard,
  HalvesCard,
  ThirdsCard,
  PanelRow,
  PanelRowWithChildren,
  FullCardWithChildren,
  BigLogo,
  BodyPanel,
  SimpleEmailSignupWidget,
  TOC,
  TOCPanel
};
