import generateSection from './generateSection'

export const generateSections = (sections, mobileView) => {
  // console.log('generating sections', sections)
  return sections.map((section, index) => {
    // console.log(`generating section: ${JSON.stringify(section)}`);
    return generateSection(
      section,
      index,
      mobileView
    );
  });
};

export default generateSections