import React, { useContext } from 'react'
import styled from 'styled-components'
import AssetsContext from '../assetsContext';
import Img from 'gatsby-image/withIEPolyfill';


// @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}) {
//   display: none;
// }

const BigLogoContainer = styled.div`
  width: ${(props) => (props.mobileView ? '25%' : '100%')};
  max-width: ${(props) => (props.mobileView ? '25%' : '100%')};
  padding: 10px;
  height: fit-content;
  align-items: center;
  display: inline-flex;
  background-color: ${p => p.backgroundColor ? p.theme.colors[p.backgroundColor] : 'none'};
  border-color: ${p => p.borderColor ? p.theme.colors[p.borderColor] : 'none'};
  border-width: ${p => p.borderWidth ? p.borderWidth : 0}px;
  border-radius: ${p => p.borderRadius ? p.borderRadius : '0'}px;
  border-style: ${p => p.borderWidth > 0 ? 'solid' : 'none'};
  box-sizing: border-box;

  ${p => p.mobileView ? 'display: none;' : '' }
`;

const BigLogoImage = styled(Img)`
  width: 100%;
  height: auto;
`;

export const BigLogo = ({className, mobileView, borderRadius, borderWidth, borderColor, logoName, backgroundColor}) => {
  
  const imagesMaxByName = useContext(AssetsContext).imagesMaxByName;
  // console.log(logoName)
  
  return (
    <BigLogoContainer borderWidth={borderWidth} borderColor={borderColor} borderRadius={borderRadius} backgroundColor={backgroundColor} className={className} mobileView={mobileView}>
      <BigLogoImage fluid={imagesMaxByName[logoName]} />
    </BigLogoContainer>
  )
}

export default BigLogo