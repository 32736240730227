import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import BodyPanelContainer from '../BodyPanelContainer';
import Headline from './Headline';

const IssueFullPanelBodyContainer = styled(BodyPanelContainer)`
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 30px;
`

const IssueFullPanelContainer = styled.div`
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  /* display: grid;
  @media (min-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 549px) {
    grid-template-columns: 1fr;
  }
  row-gap: 40px;
  column-gap: 40px;
  justify-items: stretch;
  justify-content: center; */
  background-color: ${(props) => props.theme.issuesPanel.backgroundColor};
`;

const IssueFullPanelBoxContainer = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.colors[props.color]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: lightgray -3px 3px;
`  


const IssueFullPanelListItemsContainer = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 10px;
  background-color: ${props => props.theme.colors.jiarEggshell};
  box-shadow: lightgray -3px 3px;
`

const IssueFullPanelHeadline = styled(Headline)`
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 28px;
  line-height: 28px;
  font-family: ${p => p.theme.fontFamilies.subHeader};

  div {
    margin-top: 0;
  }

`;

// When platform is expanded, these will be needed 
//
const IssueFullPanelListItem = styled.li``;

const IssueFullPanelListItemMarkdownSpan = styled(ReactMarkdown)`
  > p {
    margin-top: 1px;
    line-height: ${(props) => (props.mobileView ? '20px' : '22px')};
  }
`;


// title, color, intro, listItems, mobileView

const IssueFullPanel = ({ title, intro, color, listItems, slug, mobileView  }) => {



  return (
    <IssueFullPanelBodyContainer>
      <IssueFullPanelContainer>
        <IssueFullPanelBoxContainer id={slug} color={color}>
        <IssueFullPanelHeadline hideTopArrow={false} h={3} mobileView={mobileView}>
          {title.toUpperCase()}
        </IssueFullPanelHeadline>
          <IssueFullPanelListItemsContainer>
            <ul>
                {listItems.map((listItem) => (
                    <IssueFullPanelListItem >
                        <IssueFullPanelListItemMarkdownSpan includeNodeIndex={true} mobileView={mobileView}>
                            {listItem}
                        </IssueFullPanelListItemMarkdownSpan>
                    </IssueFullPanelListItem>
                ))}
            </ul>
          </IssueFullPanelListItemsContainer>
        </IssueFullPanelBoxContainer>
      </IssueFullPanelContainer>
    </IssueFullPanelBodyContainer>

  );
};

export default IssueFullPanel;
