import React from 'react';
import styled from 'styled-components';
import Headline from './Headline';
// import ReactMarkdown from 'react-markdown';
import BodyPanelContainer from '../BodyPanelContainer';

const IssuesPanelBodyContainer = styled(BodyPanelContainer)`
  margin-top: 0;
  margin-bottom: 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`

const IssuesPanelContainer = styled.div`
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;  
  background-color: ${(props) => props.theme.issuesPanel.backgroundColor};
`;

const IssuesPanelHeadline = styled(Headline)`
  text-align: left;
  margin-top: 0px;
  margin-bottom: ${(props) => (props.mobileView ? '0px' : '0px')};
`;

// When platform is expanded, these will be needed 
//
// const IssuesPanelListItem = styled.li``;

// const IssuesPanelListItemMarkdownSpan = styled(ReactMarkdown)`
//   > p {
//     margin-top: 1px;
//     line-height: ${(props) => (props.mobileView ? '20px' : '22px')};
//   }
// `;

const IssuesPanel = ({ title, intro, listItems, mobileView }) => {
  return (
    <IssuesPanelBodyContainer>
      <IssuesPanelContainer>
        <IssuesPanelHeadline hideTopArrow={true} h={3} mobileView={mobileView}>
          {title.toUpperCase()}
        </IssuesPanelHeadline>
        {/* <ul>
                      {listItems.map((listItem) => (
                          <IssuesPanelListItem >
                              <IssuesPanelListItemMarkdownSpan mobileView={mobileView}>
                                  {listItem}
                              </IssuesPanelListItemMarkdownSpan>
                          </IssuesPanelListItem>
                      ))}
                  </ul> */}
      </IssuesPanelContainer>
    </IssuesPanelBodyContainer>

  );
};

export default IssuesPanel;
