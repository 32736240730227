import React, { useContext } from 'react'
import styled from 'styled-components'
import AssetsContext from '../assetsContext'

const FullWidthImg = styled.img`
    width: 100%;
    height: auto;
`

const FullWidthImgContainer = styled.div`

    padding: 17px;
`

const FullWidthImage = ({imageFileName, caption}) => {

    const imageURL = useContext(AssetsContext).fileUrls[imageFileName]
    
    // TODO: enable caption on fullwidth images
    // if (caption) {
    //     const { headline, text } = caption
    // }

    return(
        <FullWidthImgContainer>
            <FullWidthImg src={imageURL}/>  
        </FullWidthImgContainer>  
    )

}

export default FullWidthImage