import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import AssetsContext from '../assetsContext';
import { ModalContext } from '../modalContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import BodyPanelContainer from '../BodyPanelContainer';

const GalleryContainer = styled(BodyPanelContainer)``;

const GalleryGrid = styled.div`
  margin-right: -7px;

  &:nth-child(${(props) => props.config.imagesPerRow}n+1) {
    clear: left;
  }
`;

const GalleryImageClearBox = styled.div`
  width: ${(props) => (props.uniformWidth ? props.w : props.w)};
  max-width: ${(props) => props.w};
  float: left;
  display: block;
`;

const GalleryImageMarginWrapper = styled.div`
  margin-right: 7px;
  margin-bottom: 7px;
  position: relative;
`;
const GalleryImageWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  display: inline-block;
  cursor: default;
  border-bottom: none;
  background: transparent;
  vertical-align: middle;
`;

const GalleryImage = styled.img`
    /* width: ${(props) => props.w}px; */
    /* height: ${(props) => props.h}px; */
    object-fit:  ${(props) =>
      props.heightCoefficient >= 1 ? 'cover' : 'scale-down'};
    left: 0px;
    width: ${(props) => (props.uniformWidth ? '100%' : '100%')};
    max-width: 100%;
    height: ${(props) =>
      props.uniformWidth
        ? (props.imagesPerRow === 2
            ? 282 * props.heightCoefficient
            : 233.75 * props.heightCoefficient) + 'px'
        : 'auto'};
    position: ${(props) => (props.uniformWidth ? 'relative' : 'absolute')};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

function renderImage(config, image, files, modalState, setModalState) {
  // console.log(`rendering ${image}`)

  const stringOnly = !(typeof image === 'object');

  function handleClick(e) {
    setModalState({
      ...modalState,
      active: !modalState.active,
      imageName: image.fileName || image,
      imageUrl: e.target.attributes.src.value,
      caption: image.caption ? image.caption : null,
    });
  }

  const { w, imagesPerRow, configuration } = config;
 
  const uniformThumbWidths = configuration.uniformThumbWidths;
  const heightCoefficient = configuration.heightCoefficient;
  const bottomPadding = configuration.bottomPadding;

  return (
    <GalleryImageClearBox
      key={stringOnly ? image : image.fileName + 'box'}
      w={w}
    >
      <GalleryImageMarginWrapper>
        <GalleryImageWrapper
          bottomPadding={bottomPadding}
          onClick={configuration.zoomOnClick ? handleClick : ''}
          key={stringOnly ? image : image.fileName}
        >
          <GalleryImage
            uniformWidth={uniformThumbWidths}
            imagesPerRow={imagesPerRow}
            heightCoefficient={heightCoefficient}
            src={stringOnly ? files[image] : files[image.fileName]}
            key={stringOnly ? files[image] : files[image.fileName]}
          />
        </GalleryImageWrapper>
      </GalleryImageMarginWrapper>
    </GalleryImageClearBox>
  );
}

const Gallery = ({ config, images }) => {
  const files = useContext(AssetsContext).fileUrls;
  const [modalState, setModalState] = useContext(ModalContext);
  const [imagesReady, setImagesReady] = useState(false);
  const { width } = useWindowDimensions();

  function configureGalleryDimensions(width, numberOfImages) {
    const output = {
      w: null,
      h: null,
      imagesPerRow: null,
    };

    if (numberOfImages < 10) {
      if (width > 640) {
        output.imagesPerRow = 4;
      } else {
        output.imagesPerRow = 2;
      }
    } else {
      if (width > 640) {
        output.imagesPerRow = 5;
      } else {
        output.imagesPerRow = 2;
      }
    }

    output.w = `${100 / output.imagesPerRow}%`;
    output.h = `${100 / output.imagesPerRow}%`;

    return output;
  }

  // console.log(useWindowDimensions())
  useEffect(() => {
    if (images) {
      setImagesReady(true);
    }
  }, [images]);

  const galleryDimensions = configureGalleryDimensions(width, images.length);
  const fullConfiguration = {
    w: galleryDimensions.w,
    h: galleryDimensions.h,
    imagesPerRow: galleryDimensions.imagesPerRow,
    configuration: config,
  };

  return (
    <GalleryContainer style={config.topPadding ? {} : { paddingTop: 0 }}>
      <GalleryGrid config={fullConfiguration}>
        {imagesReady &&
          images.map((image) => {
            return renderImage(
              fullConfiguration,
              image,
              files,
              modalState,
              setModalState
            );
          })}
      </GalleryGrid>
    </GalleryContainer>
  );
};

export default Gallery;
