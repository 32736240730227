import styled from 'styled-components'

const TextBody = styled.div`

    padding-top: 10px;

    p {
        padding: 1px 0px;
        line-height: ${props => props.mobileView ? '22px' : ''};
    }
    
    p:first-of-type {
        margin-top: ${props => props.mobileView || props.unifiedBody ? '0px' : '10px'};
        
    }

`

export default TextBody;