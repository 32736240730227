import firebaseConfig from './config';

class Firebase {
  constructor(app) {
    if (!firebaseInstance) {
      app.initializeApp(firebaseConfig);

      this.functions = app.functions();
      this.analytics = app.analytics();
    }
  }

  async submitSignup({ name, email, phone, willKnock, willHost, willDigital }) {
    const createSignupCallable = this.functions.httpsCallable('acceptSignup');
    return createSignupCallable({
      name,
      email,
      phone,
      willKnock,
      willHost,
      willDigital,
    });
  }

  async submitMeetSandySignup({ firstName, lastName, email, phone, streetAddress1, streetAddress2, city }) {
    const createMeetSandySignupCallable = this.functions.httpsCallable('acceptMeetSandySignup');
    return createMeetSandySignupCallable({
      firstName,
      lastName,
      email
    });
  }

  async submitSimpleEmailSignup({ email }) {
    const createSubmitSimpleEmailSignupCallable = this.functions.httpsCallable('acceptSimpleEmailSignup');
    return createSubmitSimpleEmailSignupCallable({
      email,
    });
  }

}

let firebaseInstance;

function getFirebaseInstance(app) {
  if (!firebaseInstance && app) {
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  } else if (firebaseInstance) {
    return firebaseInstance;
  } else {
    return null;
  }
}

export default getFirebaseInstance;
