import React from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.div`
  display: inline-block;
  padding-left: 2px;
  padding-bottom: ${(props) => (props.mobileView ? '1px' : '5px')};

  label {
    letter-spacing: -0.5px;
    display: block;
    padding-top: ${(props) => props.mobileView && '3px'};
  }
  > input {
    opacity: 0;
    display: none;
  }
  > input + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 17px;
      height: 17px;
      border: 1px solid #aaa;
      background: #f8f8f8;
      border-radius: 3px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    }
    &:after {
      content: '✔';
      position: absolute;
      top: 2px;
      left: 3px;
      font-size: 16px;
      color: #09ad7e;
      transition: all 0.2s;
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: #999;
    }
  }
  > input:disabled + label {
    color: #aaa;
  }
  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border: 1px dotted blue;
    }
  }
`;

const CustomCheckbox = ({
  labelText,
  checked,
  toggleChecked,
  stateName,
  className,
  mobileView,
}) => {
  return (
    <StyledCheckbox
      onClick={(e) => toggleChecked(e, stateName)}
      className={className}
      mobileView={mobileView}
    >
      <input type="checkbox" checked={checked} />
      <label>{labelText}</label>
    </StyledCheckbox>
  );
};

export default CustomCheckbox;
