import styled from 'styled-components'

const Paragraphs = styled.div`

    padding-top: ${p => p.reduceFootprint ? 5 : 10}px;

    p {
        ${p => p.fontSize ? `
          font-size: ${p.fontSize}px;
          line-height: ${p.mobileView ? p.mobileFontSize ? p.mobileFontSize : 22 : p.fontSize + 4}px;
        ` : `
          line-height: ${props => props.mobileView ? 22 : ''}px;
        `}

        padding: 1px 0px;

        ${p => p.reduceFootprint ? `
          margin-bottom: 5px;
        `: ``}

        ${p => p.noBottomMargin ? `
          margin-bottom: 0px;
        `: ``}
    }
    
    p:first-of-type {
        margin-top: ${props => props.mobileView || props.unifiedBody || props.reduceFootprint ? '0px' : '10px'};
        
    }

`

export default Paragraphs;