import styled from 'styled-components'

// SquatButton API
// props: {
//   backgroundColor,
//   color,
//   hoverBorderColor,
//   hoverColor   
// }

export const SquatButton = styled.button`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  border-radius: 10px;
  justify-self: right;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: ${p => p.backgroundColor ? p.theme.colors[p.backgroundColor] : p.theme.colors.jiarPurple};
  color: ${p => p.color ? p.theme.colors[p.color] : p.theme.colors.jiarEggshell};
  cursor: pointer !important;
  letter-spacing: -0.5px;
  border-width: 4px;
  border-style: solid;
  border-color: ${p => p.borderColor ? p.theme.colors[p.borderColor] : p.backgroundColor ? p.theme.colors[p.backgroundColor] : p.theme.colors.jiarPurple};

  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  padding: 6px;
  
  transition: 0.1s 0s ease-in-out;
  transition-property: color, border-color;

  &:disabled {

  }

  @media (hover: hover) {
    &:hover {
      border-color: ${p => p.hoverBorderColor ? p.theme.colors[p.hoverBorderColor] : p.theme.colors.jiarTeal};
      color: ${p => p.hoverColor ? p.theme.colors[p.hoverColor] : p.theme.colors.jiarTeal};
      background-color: ${p => p.hoverBackgroundColor ? p.theme.colors[p.hoverBackgroundColor] : ''};
    }
  }

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 16px;
    line-height: 16px;
    margin-right: auto;
    padding: 5px;
    margin-top: 5px;
  }
`

export default SquatButton