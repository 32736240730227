import React, { useMemo } from 'react'
import {renderParagraphs} from '../../../_utils'
import TextBody from '../TextBody'

const TextSection = ({mobileView, textColor, body}) => {
  const renderedText = useMemo(() => {
    return renderParagraphs(body)
  }, [body])
  return (
    <TextBody unifiedBody={true} mobileView={mobileView} color={textColor}>
      {renderedText}
    </TextBody>
  )
}

export default TextSection