import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

export const CustomMarkdown = styled(ReactMarkdown)`
  strong {
    font-family: ${p => p.theme.fontFamilies.bold};
    font-weight: 700;
  }
`

export default CustomMarkdown