import React from 'react';
import styled from 'styled-components';

const TextCubeContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  background: ${(props) => props.theme.colors[props.bgColor]};
  color: ${(props) => props.theme.colors[props.textColor]};
  display: table;
  border-radius: 0px;

  > span {
    margin: 10px;
    display: table-cell;
    padding: 10px;
    font-family: ${(props) => props.theme.fontFamilies.header};
    letter-spacing: -0px;
    font-size: ${(props) => props.fontSize}px;
    vertical-align: middle;
    height: ${(props) =>
      (props.cubesPerRow === 2
        ? 282 * props.heightCoefficient
        : 233.75 * props.heightCoefficient) + 'px'};
  }
`;

export const ModalThumbTextCubeContainer = styled.div`
box-sizing: border-box;
  padding: ${p => p.windowWidth >= 980 ? '100' : 100 / (980/100)}px;
  background: ${(props) => props.theme.colors[props.bgColor]};
  color: ${(props) => props.theme.colors[props.textColor]};
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
  display: table;
  > span {
    margin: 10px;
    display: table-cell;
    padding: 10px;
    font-family: ${(props) => props.theme.fontFamilies.header};
    letter-spacing: -0px;
    font-size: ${(props) => props.fontSize}px;
    vertical-align: middle;
  }
`

const ModalTextCubeContainer = styled.div`
  width: calc(100vw - 50px);
  max-width: 920px;
  height: 100%;
  max-height: 300px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  padding: ${p => p.windowWidth >= 980 ? '100' : 100 / (980/p.windowWidth)}px;
  background: ${(props) => props.theme.colors[props.bgColor]};
  color: ${(props) => props.theme.colors[props.textColor]};
  display: table;
  border-radius: 0px;
  box-sizing: border-box;

  > span {
    margin: 10px;
    display: table-cell;
    padding: 10px;
    font-family: ${(props) => props.theme.fontFamilies.header};
    letter-spacing: -0px;
    font-size: ${(props) => props.fontSize}px;
    vertical-align: middle;
    height: ${(props) =>
      (props.cubesPerRow === 2
        ? 282 * props.heightCoefficient
        : 233.75 * props.heightCoefficient) + 'px'};
  }
`;

function getModalFontSize(windowWidth) {
  return windowWidth > 980
          ? 60
          : (windowWidth / 980) * 0.9 * 60
}

function getFontSize(windowWidth) {
  let fontSize = 20;

  if (windowWidth <= 355) {
    fontSize = 13;
  } else if (windowWidth <= 395) {
    fontSize = 14;
  } else if (windowWidth <= 440) {
    fontSize = 15;
  } else if (windowWidth <= 480) {
    fontSize = 17;
  } else if (windowWidth <= 530) {
    fontSize = 18;
  } else if (windowWidth <= 600) {
    fontSize = 20;
  } else if (windowWidth <= 720) {
    fontSize = 23;
  } else if (windowWidth <= 750) {
    fontSize = 13;
  } else if (windowWidth <= 770) {
    fontSize = 14;
  } else if (windowWidth <= 800) {
    fontSize = 15;
  } else if (windowWidth <= 840) {
    fontSize = 16;
  } else if (windowWidth <= 910) {
    fontSize = 17;
  } else if (windowWidth <= 960) {
    fontSize = 18;
  } else if (windowWidth <= 1000) {
    fontSize = 19;
  }

  return fontSize
}

export const ModalTextThumbCube = ({cube}) => {
  let fontSize = getModalFontSize(100)
  return (<ModalThumbTextCubeContainer fontSize={fontSize} bgColor={cube.bgColor} textColor={cube.textColor}>
    <span>{cube.text}</span>
  </ModalThumbTextCubeContainer>
)}

export const ModalTextCube = ({ windowWidth, cube }) => {
  let fontSize = getModalFontSize(windowWidth)

  return (
    <ModalTextCubeContainer
      bgColor={cube.bgColor}
      textColor={cube.textColor}
      fontSize={fontSize}
      windowWidth={windowWidth}
    >
      <span>{cube.text}</span>
    </ModalTextCubeContainer>
  )

}

const TextCube = ({ height, width, cube, windowWidth }) => {
  let fontSize = getFontSize(windowWidth)
  return (
    <TextCubeContainer
      bgColor={cube.bgColor}
      textColor={cube.textColor}
      fontSize={fontSize}
    >
      <span>{cube.text}</span>
    </TextCubeContainer>
  );
};

export default TextCube;

// import React from 'react';
// import styled from 'styled-components';
// import useFitText from '../../../hooks/useFitText';

// const TextCubeContainer = styled.div`
//   width: ${(props) => props.width};
//   max-width: ${(props) => props.width};
//   height: 100%;
//   margin-top: auto;
//   margin-bottom: auto;
//   background: ${(props) => props.theme.colors[props.bgColor]};
//   color: ${(props) => props.theme.colors[props.textColor]};
//   display: table;
//   border-radius: 0px;
//   overflow: hidden;
//   font-size: 20px;
//   > div {
//       padding: 10px;
//       font-family: ${(props) => props.theme.fontFamilies.header};
//       letter-spacing: -2px;
//       vertical-align: middle;
//     }
//   }
// `;

// const TextCube = ({ height, width, cube, windowWidth }) => {
//   const { fontSize, ref } = useFitText();

//   // let fontSize = 16;

//   // if (windowWidth <= 355) {
//   //   fontSize = 10;
//   // } else if (windowWidth <= 395) {
//   //   fontSize = 11;
//   // } else if (windowWidth <= 440) {
//   //   fontSize = 12;
//   // } else if (windowWidth <= 480) {
//   //   fontSize = 14;
//   // } else if (windowWidth <= 530) {
//   //   fontSize = 15;
//   // } else if (windowWidth <= 600) {
//   //   fontSize = 17;
//   // } else if (windowWidth <= 720) {
//   //   fontSize = 20;
//   // } else if (windowWidth <= 750) {
//   //   fontSize = 10;
//   // } else if (windowWidth <= 770) {
//   //   fontSize = 11;
//   // } else if (windowWidth <= 800) {
//   //   fontSize = 12;
//   // } else if (windowWidth <= 840) {
//   //   fontSize = 13;
//   // } else if (windowWidth <= 910) {
//   //   fontSize = 14;
//   // } else if (windowWidth <= 960) {
//   //   fontSize = 15;
//   // } else if (windowWidth <= 1000) {
//   //   fontSize = 16;
//   // }

//   return (
//     <TextCubeContainer
//       bgColor={cube.bgColor}
//       textColor={cube.textColor}
//       fontSize={fontSize}
//       height={height}
//       width={width}
//     >
//       <div ref={ref} style={{ fontSize, height, width }}>
//         {cube.text}
//       </div>
//     </TextCubeContainer>
//   );
// };

// export default TextCube;
