import React from 'react'
import BodyComponents from '../BodyComponents';

export const generateSection = ({ type, params }, sectionIndex, mobileView) => {
  // console.log(`generating ${type}:`, BodyComponents[type])
  if (BodyComponents[type]) {
    const SectionComponent = BodyComponents[type];
    // console.log('sectionComponent:', SectionComponent);
    return (
      <SectionComponent
        {...params}
        mobileView={mobileView}
        key={type + sectionIndex}
      />
    );
  } else {
    // console.log(`couldn't find component ${type}`)
    return <div>{type}</div>;
  }
}

export default generateSection
