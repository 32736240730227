import React from 'react';
import ImageWrapper from './ImageWrapper';
import Image from './Image';

const ImageCube = ({
  height,
  width,
  imageFluid,
  cube
}) => {
  
  // TODO: Reinstate modal
  //
  // function handleClick(e) {
  //     setModalState({
  //         ...modalState,
  //         active: !modalState.active,
  //         imageName: cube.fileName || cube,
  //         imageUrl: e.target.attributes.src.value,
  //         caption: (cube.caption ? cube.caption : null)
  //     })
  // }

  return (
    <ImageWrapper cubeH={height} cubeW={width}>
      <Image
        cubeH={height}
        cubeW={width}
        fluid={imageFluid}
        scaleDown={cube.scaleDown}
      />
    </ImageWrapper>
  );
};

export default ImageCube;

// return (
//     <GalleryImageWrapper bottomPadding={bottomPadding}
//     onClick={configuration.zoomOnClick ? handleClick : ''}
//     key={stringOnly ? cube : cube.fileName }>
//         <GalleryImage uniformWidth={uniformThumbWidths} imagesPerRow={cubesPerRow}
//                     heightCoefficient={heightCoefficient}
//                     scaleDown={cube.scaleDown && cube.scaleDown || false}
//                     src={stringOnly ? files[cube] : files[cube.fileName]}
//                     key={stringOnly ? files[cube] : files[cube.fileName]}/>
//     </GalleryImageWrapper>
// )

// function renderImageCube(config, cube, files, modalState, setModalState, windowWidth) {
//     console.log(`rendering ${cube}`)

//     const stringOnly = !(typeof cube === 'object');

//     return (
//         <GalleryImageClearBox key={stringOnly ? cube : cube.fileName + "box"} w={w}>
//             <GalleryImageMarginWrapper>
//                 <GalleryImageWrapper bottomPadding={bottomPadding}
//                     onClick={configuration.zoomOnClick ? handleClick : ''}
//                     key={stringOnly ? cube : cube.fileName }>
//                     <GalleryImage uniformWidth={uniformThumbWidths} imagesPerRow={cubesPerRow}
//                                 heightCoefficient={heightCoefficient}
//                                 scaleDown={cube.scaleDown && cube.scaleDown || false}
//                                 src={stringOnly ? files[cube] : files[cube.fileName]}
//                                 key={stringOnly ? files[cube] : files[cube.fileName]}/>
//                 </GalleryImageWrapper>
//             </GalleryImageMarginWrapper>
//         </GalleryImageClearBox>
//     )
// }
