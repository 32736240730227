import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { ModalContext } from '../../modalContext';
import AssetsContext from '../../assetsContext';

import Headline from '../Headline';
import BodyPanelContainer from '../../BodyPanelContainer';
import CubeContainer from './CubeContainer';
import ImageCube from './ImageCube';
import TextCube, { ModalTextCube, ModalTextThumbCube } from './TextCube';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useScrollbarCheck from '../../../hooks/useScrollbarCheck';
import { ModalImage, ModalThumb } from './ModalImage';

const StoryGridContainer = styled.div`
  padding-right: 5px;
`;

const StoryGridBodyContainer = styled(BodyPanelContainer)``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.cubesPerRow},
    ${(props) => props.cubeW}px
  );
  grid-template-rows: repeat(
    ${(props) => props.rowsOfCubes},
    ${(props) => props.cubeH}px
  );
  gap: 7px 7px;
  margin-top: 20px;
`;

const StoryGalleryHeadline = styled(Headline)`
  /* position: relative;
  left: 0; */
  /* margin-right: 0;
  padding: 5px 0px;
  background: ${(props) => props.theme.colors[props.bgColor]}; */
`;

// function configureGalleryDimensions(width, numberOfCubes) {
//   const output = {
//     w: null,
//     h: null,
//     cubesPerRow: null,
//     rowsOfCubes: null,
//   };

//   if (numberOfCubes < 10) {
//     if (width > 640) {
//       output.cubesPerRow = 4;
//     } else {
//       output.cubesPerRow = 2;
//     }
//   } else {
//     if (width > 640) {
//       output.cubesPerRow = 4;
//     } else {
//       output.cubesPerRow = 2;
//     }
//   }

//   output.rowsOfCubes = numberOfCubes / output.cubesPerRow;

//   output.w = `${100 / output.cubesPerRow}%`;
//   output.h = `${100 / output.cubesPerRow}%`;

//   return output;
// }

function calculateCubeDimensions(
  windowW,
  numberOfCubes,
  scrollbarWidth,
  hasScrollbar
) {
  // console.log('calculating cube dimensions for scrollbarwidth ', scrollbarWidth);
  if (windowW > 990) {
    windowW = 990;
  }

  const output = {
    cubeW: null,
    cubeH: null,
    cubesPerRow: null,
  };

  if (windowW > 720) {
    output.cubesPerRow = 4;
  } else {
    output.cubesPerRow = 2;
  }

  output.cubeW =
    (windowW -
    40 /*body padding*/ -
    7 * (output.cubesPerRow - 1) /*2x or 3x 7px internal separation */ -
      scrollbarWidth) /
    output.cubesPerRow;
  output.cubeH = output.cubeW;

  return output;
}

export const generateModalThumbs = ({cubes, imagesHeightenedByName, imagesWidenedByName}) => {

  const modalThumbs = cubes.map((cube, i, cubes) => {
      
      const image = cube.type === 'image' && imagesWidenedByName[cube.fileName].original.width > imagesWidenedByName[cube.fileName].original.height 
                      ? imagesWidenedByName[cube.fileName] 
                      : imagesHeightenedByName[cube.fileName]

      return cube.type === 'text' ? (
                <ModalTextThumbCube key={'thumb'+i} cube={cube}></ModalTextThumbCube>
              ) : (
                <ModalThumb key={'thumb'+i} imageName={cube.fileName} image={image}/>
              )
    })

  return modalThumbs
}

export const generateModalViews = ({cubes, windowH, windowW, imagesMaxByName}) => {
  return [
    ...cubes.map((cube, i, cubes) => (
      <>
        {
          {
            text: (
            <ModalTextCube
              cube={cube}
              windowWidth={windowW}
            />
            ),
            image: (
              <ModalImage includedMargins={cube.includedMargins} windowWidth={windowW} windowHeight={windowH} imageName={cube.fileName} caption={cube.caption} image={imagesMaxByName[cube.fileName]}/>
            )
          }[cube.type]
        }
      </>
    ))
  ]
}

const generateCubes = ({cubes, cubeH, cubeW, windowWidth, imagesWidenedByName, zoomToCube}) => {

  return [
    ...cubes.map((cube, i, cubes) => (
      <CubeContainer key={`gallerycube${i}`} cubeH={cubeH} cubeW={cubeW} margin={20} onClick={() => { zoomToCube(i) }}>
        {
          {
            text: (
              <TextCube
                height={cubeH}
                width={cubeW}
                cube={cube}
                windowWidth={windowWidth}
              />
            ),
            image: (
              <ImageCube
                height={cubeH}
                width={cubeW}
                imageFluid={imagesWidenedByName[cube.fileName || cube]}
                cube={cube}
                windowWidth={windowWidth}
              />
            ),
          }[cube.type]
        }
      </CubeContainer>
    ))
  ]

}

// const wrapCubesForGrid = ({cubeComponents, cubeH, cubeW, zoomToCube}) => {

//   return [...cubeComponents.map((cubeComponent, i) => (
    
//       {cubeComponent}
//     </CubeContainer>
//   ))]

// }

// const wrapCubesForModal = ({cubeComponents, cubeCaptions}) => {

//   return [...cubeComponents.map((cubeComponent, i) => (
//     <>{cubeComponent}</>
//   ))]

// }

const StoryGallery = ({ config, title, cubes, mobileView }) => {

  const {
    imagesWidenedByName,
    imagesHeightenedByName,
    imagesMaxByName
  } = useContext(AssetsContext);

  const {initContent} = useContext(ModalContext);
  const { width, height } = useWindowDimensions();
  const hasScrollbar = useScrollbarCheck();
  
  let windowWidth = width;
  let windowHeight = height;


  // eslint-disable-next-line no-unused-vars
  const { zoomOnClick, backgroundColor } = config;
  const { text, titleColor, titleShadowColor } = title;

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const [cubeComponents, setCubeComponents ] = useState(null)
  const [modalViews, setModalViews] = useState(null)
  const [modalThumbs, setModalThumbs] = useState(null)

  const { cubeW, cubeH, cubesPerRow, rowsOfCubes } = calculateCubeDimensions(
    width,
    cubes.length,
    scrollbarWidth,
    hasScrollbar
  );

  useEffect(() => {
    if (!modalViews && !modalThumbs) {
      setModalViews(generateModalViews({cubes, cubeH, cubeW, windowH: windowHeight, windowW: windowWidth, imagesMaxByName}))
      setModalThumbs(generateModalThumbs({cubes, imagesWidenedByName, imagesHeightenedByName}))
    }
  }, [cubes, cubeH, cubeW, windowHeight, windowWidth, imagesMaxByName, imagesWidenedByName, imagesHeightenedByName])

  useEffect(() => {
    const zoomToCube = (cubeId) => {
      initContent(modalViews, modalThumbs, cubeId)
    }
    if(modalThumbs && modalViews && !cubeComponents) {
      setCubeComponents(generateCubes({cubes, cubeH, cubeW, windowWidth, imagesWidenedByName, zoomToCube}))
    }
  }, [modalThumbs, modalViews, cubes, cubeH, cubeW, windowWidth, imagesWidenedByName])


  // let cubeComponents = useMemo(() => generateCubes({cubes, cubeH, cubeW, windowWidth, imagesWidenedByName, zoomToCube}), [cubes, cubeH, cubeW, windowWidth, imagesWidenedByName, zoomToCube])
  // let modalViews = useMemo(() => generateModalViews({cubes, cubeH, cubeW, windowH: windowHeight, windowW: windowWidth, imagesMaxByName}), [cubes, cubeH, cubeW, windowHeight, windowWidth, imagesMaxByName])
  // let modalThumbs = useMemo(() => generateModalThumbs({cubes, imagesWidenedByName, imagesHeightenedByName}), [cubes, imagesWidenedByName, imagesHeightenedByName])

  useEffect(() => {
    if (typeof window === 'object') {
      setScrollbarWidth(window.innerWidth - document.body.clientWidth);
    }
  }, []);

  return (
    <StoryGridBodyContainer bgColor={backgroundColor}>
      <StoryGridContainer>
        <StoryGalleryHeadline
          mobileView={mobileView}
          bgColor={backgroundColor}
          color={titleColor}
          shadowColor={titleShadowColor}
        >
          {text}
        </StoryGalleryHeadline>
        <Grid
          cubeW={cubeW}
          cubeH={cubeH}
          cubesPerRow={cubesPerRow}
          rowsOfCubes={rowsOfCubes}
        >
          {cubeComponents}
        </Grid>
      </StoryGridContainer>
    </StoryGridBodyContainer>
    
  );
};

export default StoryGallery;