import React from 'react'
import styled from 'styled-components'

const ModalOverlayImageContainer = styled.div`
    display: flex;
    position: relative;
    /* height: ${props => props.h}px;
    width: ${props => props.w}px; */
    @media (max-width: 980px) {
        max-width: calc(100vw${p => p.$includedMargins ? '' : '- 40px'});
    }
    @media (min-width: 981px) {
        max-width: ${p => p.$includedMargins ? '980px' : '930px'};
    }
    height: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    box-sizing: border-box;
`

const ModalOverlayImage = styled.img`
    /* height: ${props => props.h}px;
    width: ${props => props.w}px;
    position: absolute; */
    /* padding-left: 20px;
    padding-right: 20px; */
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    /* ${p => p.orientation === "wide" ? `
        max-height: calc(100vh - 191px);
        max-width: calc(100vw - 50px);
    ` : p.orientation === 'square' ? `
        max-height: calc(100vh - 191px);
        max-width: calc(100vw - 50px);
    ` : `
        max-height: calc(100vh - 191px);
        max-width: calc(100vw - 50px);
    `} */


    ${p => p.orientation === "wide" ? `
        max-height: calc(100vh - 191px);
        max-width: ${p.$includedMargins ? `100%` : `calc(100% - 70px)`};
    ` : p.orientation === 'square' ? `
        max-height: calc(100vh - 191px);
        max-width: ${p.$includedMargins ? `100%` : `calc(100% - 70px)`};
    ` : `
        max-height: 100%;
        max-width: ${p.$includedMargins ? `100%` : `calc(100% - 70px)`};

    `}

    
`
const ModalCaptionBox = styled.div`
    text-align: left;
    z-index: 10000;
    display: block;
    position: absolute;
    overflow: hidden;
    align-self: center;
    background-color: rgba(7,7,7,0.8);
    color: white;
    padding: 2px 10px;
    font-family: ${props => props.theme.fontFamilies.paragraph};
    width: ${props => (props.w)}px;
    bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;    
    box-sizing: border-box; 


    p {
        line-height: ${props => {
          if (props.winW > 700) {
              return '16';
          } else if (props.winW > 600) {
              return '14';
          } else {
              return '13';
          }
        }}px;
        font-size: ${props => {
          if (props.winW > 700) {
              return '14';
          } else if (props.winW > 600) {
              return '12';
          } else {
              return '11';
          }
        }}px;
    }

    h3 {
        font-family: ${props => props.theme.fontFamilies.subHeader};
        font-size: ${props => {
          if (props.winW > 700) {
              return '14';
          } else if (props.winW > 600) {
              return '12';
          } else {
              return '11';
          }
        }}px;
    }
`

export const ModalThumb = ({imageName, image}) => {
  
    return <img key={imageName+'thumb'} src={image.src} alt={'thumbnail'} />
}


export const ModalImage = ({windowWidth, windowHeight, imageName, image, caption = null, includedMargins}) => {

    const imageDimensions = { w: image.original.width, h: image.original.height }
    let ids = imageDimensions

    const imageUrl = image.src
    
    const maxFrameHeight = windowHeight - 121;
    const maxFrameWidth = windowWidth > 980 ? 980 - 75 : windowWidth - 50

    let actualImageWidth;
    let actualImageHeight;


    if (imageDimensions.w > imageDimensions.h) {
        if (imageDimensions.w > maxFrameWidth) {
            if (imageDimensions.h*(maxFrameWidth/imageDimensions.w) > maxFrameHeight) {
                actualImageHeight = maxFrameHeight
                actualImageWidth = imageDimensions.w*(actualImageHeight/imageDimensions.h)
            } else {
                actualImageWidth = maxFrameWidth
                actualImageHeight = imageDimensions.h*(actualImageWidth/imageDimensions.w)
            }
        } else {
            actualImageHeight = maxFrameHeight;
            actualImageWidth = imageDimensions.w*(maxFrameHeight/imageDimensions.h)
        }
    } else {
        if (imageDimensions.h > maxFrameHeight) {
            if (imageDimensions.w*(maxFrameHeight/imageDimensions.h) > maxFrameWidth) {
                actualImageWidth = maxFrameWidth
                actualImageHeight = imageDimensions.h*(actualImageWidth/imageDimensions.w)
            } else {
                actualImageHeight = maxFrameHeight
                actualImageWidth = imageDimensions.w*(actualImageHeight/imageDimensions.h)
            }
        } else {
            actualImageWidth = maxFrameWidth
            actualImageHeight = imageDimensions.h*(maxFrameWidth/imageDimensions.w)
        }
    }

    let imageMargins = {
        lr: (windowWidth - actualImageWidth) / 2,
        tb: (windowHeight - actualImageHeight) / 2
    }

    return (<ModalOverlayImageContainer imageMargins={imageMargins} w={actualImageWidth} h={actualImageHeight}  >
        <ModalOverlayImage $includedMargins={includedMargins} key={imageName+'modal'} orientation={ids.w > ids.h ? 'wide' : ids.w === ids.h ? 'square' : 'tall' } imageMargins={imageMargins} w={actualImageWidth} h={actualImageHeight} src={imageUrl}/>
        {!!caption &&
                <ModalCaptionBox winW={windowWidth} imageMargins={imageMargins} w={actualImageWidth} h={actualImageHeight}>
                    <h3>{caption.title}</h3>
                    <p>{caption.text}</p>
                </ModalCaptionBox>
        }
    </ModalOverlayImageContainer>)

}