import React, {
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import Headline from '../Headline';
import BodyPanelContainer from '../../BodyPanelContainer';
import AssetsContext from '../../assetsContext';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

let EndorsementsGalleryWrapper = styled(BodyPanelContainer)`
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const EndorsementsGalleryContainer = styled.div`
  background-color: 'white';
  color: 'black';
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-bottom: 10px;
  padding-right: 10px;
`;

let EndorsementsGallerySwitcher = styled.div<{ mobileView: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.mobileView ? 'column' : 'column')};
`;

let EndorsementsGallerySwitcherSection = styled.div<{ mobileView: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
`;

let EndorsementsGallerySwitcherSectionHeadline = styled(Headline)`
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  ${(p) =>
    p.mobileView
      ? `
    font-size: 22px;
  `
      : `
    font-size: 22px;
  `}

  > h2 {
    padding: 0;
  }
`;

let EndorsementsGallerySwitcherSectionContents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
`;

let EndorsementsGallerySwitcherThumbnailContainer = styled.div<{
  mobileView: boolean;
  setIndex: number;
}>`
  display: grid;
  padding: 1px;
  border: 2px solid transparent;
  border-radius: 5px;
  margin: auto 1px auto 1px;
  min-height: ${(p) => (p.setIndex === 0 ? '100px' : '50px')};
  transition: border 0.3s linear;
  cursor: pointer;
  width: ${(p) =>
    p.mobileView
      ? p.setIndex > 0
        ? '100%'
        : '40%'
      : p.setIndex > 0
      ? '40%'
      : '20%'};
  align-self: center;
  > a {
    color: black;

    &:hover {
      color: ${(props) => props.theme.links.hover};
    }
  }
`;

let EndorsementsGallerySwitcherThumbnail = styled.img<{
  borderless: boolean;
  setIndex: number;
}>`
  box-sizing: border-box;
  padding: 0px;
  margin: ${(p) => (p.setIndex > 0 ? 'auto 0 auto 0' : 'auto')};
  border-radius: ${(p) => (p.borderless ? '0%' : '50%')};
  max-height: ${(p) => (p.setIndex === 0 ? '100px' : '50px')};
  max-width: ${(p) => (p.setIndex === 0 ? '100px' : '50px')};
  border: ${(p) =>
    p.borderless ? '' : `2px solid ${p.theme.endorsements.thumbBorderColor}`};
  background-color: ${(p) =>
    p.borderless ? 'clear' : p.theme.endorsements.thumbBackgroundColor};
`;

let EndorsementsGallerySwitcherTextBox = styled.div<{ setIndex: number }>`
  text-align: center;
  display: grid;
  padding: 5px;
  ${(p) =>
    p.setIndex > 0
      ? `
    margin-left: 0;
    padding: 5px 5px 5px 10px;
  `
      : `
    padding: 5px;  
    `}
`;

let EndorsementsGallerySwitcherDescText = styled.span<{ setIndex: number }>`
  font-family: ${(p) => p.theme.fontFamilies.paragraph};
  font-size: 12px;
  ${(p) =>
    p.setIndex > 0
      ? `
    text-align: left;
  `
      : ``}
`;

let EndorsementsGallerySwitcherNameText = styled(Headline)<{
  setIndex: number;
}>`
  text-align: ${(p) => (p.setIndex > 0 ? 'left' : 'center !important')};
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 0;
  margin-right: 0;
  align-content: center;
  display: block;
  line-height: ${(p) => (p.setIndex === 0 ? '18px' : '16px')};
  ${(p) => (p.setIndex === 0 ? 'font-size: 20px;' : 'font-size: 18px;')}
`;

let EndorsementsGalleryDisplayPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  max-height: 250px;
  height: 250px;
  width: 100%;
  margin-top: 25px;

  transition-delay: 0.3s;
  transition-duration: 0.3s;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
`;

let EndorsementsGalleryDisplayPanelLeft = styled.div`
  box-sizing: border-box;
  align-content: center;
  width: 30%;
  min-width: 25%;
  max-width: 50%;
`;

let EndorsementsGalleryDisplayPanelImageContainer = styled.div`
  display: flex;
  height: 100px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
`;

let EndorsementsGalleryDisplayPanelImage = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  max-height: 100%;
`;

let EndorsementsGalleryDisplayPanelRight = styled.div`
  padding: 10px 25px 10px 10px;
  margin-left: 10px;
  height: auto;
  border: 3px solid ${(p) => p.theme.colors.jiarEggshell};
  border-radius: 5px;
  min-width: 40%;
`;

let EndorsementsGalleryDisplayPanelAttribution = styled.div``;

let EndorsementsGalleryDisplayPanelName = styled(Headline)`
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 0px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: grid;
  justify-content: right;
`;

let EndorsementsGalleryDisplayPanelDescription = styled(Headline)`
  font-size: 20px;
  padding-top: 0px;
  margin-top: 0px;
  display: grid;
  justify-content: right;
`;

let EndorsementsGalleryDisplayPanelQuote = styled.blockquote`
  font-family: ${(p) => p.theme.fontFamilies.paragraph};
  font-style: italic;
  position: relative;
  margin: 0.5em;
  padding: 0.5em 2em 0.5em 3em;
`;
let EndorsementsGalleryDisplayPanelQuoteWithAdornments = styled(
  EndorsementsGalleryDisplayPanelQuote
)`
  &:before {
    font-family: ${(p) => p.theme.fontFamilies.paragraph};
    position: absolute;
    font-style: italic;
    font-size: 4em;
    line-height: 1;
    top: 0;
    left: 0;
    content: '\\201C';
  }

  &:after {
    font-family: ${(p) => p.theme.fontFamilies.paragraph};
    position: absolute;
    float: right;
    font-size: 4em;
    line-height: 1;
    right: 0.2em;
    bottom: -0.5em;
    content: '\\201D';
  }
`;

const EndorsementsGallerySwitcherItemContainer = styled.div<{
  setIndex: number;
}>`
  display: flex;
  flex-direction: ${(p) => (p.setIndex === 0 ? 'column' : 'row')};
`;

interface IEndorsement {
  name: string;
  desc: string;
  thumb: string;
  image: string;
  url: string;
  quote: string[];
}

interface IEndorsementSet {
  type: 'Elected Leaders' | 'Community Organizations';
  switcherDisplay: 'string'; //
  endorsements: IEndorsement[]; 
  borderlessThumbs?: boolean; //
}

interface IEndorsementsGallery {
  title: string;
  endorsementSets: IEndorsementSet[];
  mobileView: boolean;
  expandOnDesktop: boolean; //
}

export default ({
  title,
  endorsementSets,
  expandOnDesktop,
  mobileView,
}: IEndorsementsGallery) => {
  const files = useContext(AssetsContext).fileUrls;

  const focus = [0, 0];

  const { width, height } = useWindowDimensions();

  mobileView = width ? width < 700 : false;

  return (
    <EndorsementsGalleryWrapper>
      <EndorsementsGalleryContainer>
        <Headline mobileView={mobileView}>Endorsements</Headline>
        {expandOnDesktop && !mobileView && focus[0] !== 9999 && (
          <EndorsementsGalleryDisplayPanel
            key={`${
              endorsementSets[focus[0]].endorsements[focus[1]].name
            }-details`}
          >
            <EndorsementsGalleryDisplayPanelLeft>
              <EndorsementsGalleryDisplayPanelImageContainer>
                <EndorsementsGalleryDisplayPanelImage
                  src={
                    files[
                      endorsementSets[focus[0]].endorsements[focus[1]].image
                    ]
                  }
                />
              </EndorsementsGalleryDisplayPanelImageContainer>
            </EndorsementsGalleryDisplayPanelLeft>
            <EndorsementsGalleryDisplayPanelRight>
              <EndorsementsGalleryDisplayPanelQuoteWithAdornments>
                {endorsementSets[focus[0]].endorsements[focus[1]].quote}
              </EndorsementsGalleryDisplayPanelQuoteWithAdornments>
              <EndorsementsGalleryDisplayPanelName h={2} hideTopArrow={true}>
                {endorsementSets[focus[0]].endorsements[focus[1]].name}
              </EndorsementsGalleryDisplayPanelName>
              <EndorsementsGalleryDisplayPanelDescription
                h={3}
                hideTopArrow={true}
              >
                {endorsementSets[focus[0]].endorsements[focus[1]].desc}
              </EndorsementsGalleryDisplayPanelDescription>
            </EndorsementsGalleryDisplayPanelRight>
          </EndorsementsGalleryDisplayPanel>
        )}
        <EndorsementsGallerySwitcher mobileView={mobileView}>
          {endorsementSets.map((endorsementSet, setIndex) => (
            <EndorsementsGallerySwitcherSection
              mobileView={mobileView}
              key={endorsementSet.type}
            >
              <EndorsementsGallerySwitcherSectionHeadline
                h={2}
                hideTopArrow={true}
                mobileView={mobileView}
              >
                {endorsementSet.type}
              </EndorsementsGallerySwitcherSectionHeadline>
              <EndorsementsGallerySwitcherSectionContents>
                {endorsementSet.endorsements.map(
                  (endorsement, endorsementIndex) => (
                    <EndorsementsGallerySwitcherThumbnailContainer
                      mobileView={mobileView}
                      setIndex={setIndex}
                      key={`endorsement${endorsementIndex}`}
                    >
                      <a href={endorsement.url} target="_blank">
                        <EndorsementsGallerySwitcherItemContainer
                          setIndex={setIndex}
                        >
                          <EndorsementsGallerySwitcherThumbnail
                            borderless={endorsementSet.borderlessThumbs || false}
                            src={files[endorsement.thumb]}
                            setIndex={setIndex}
                          />
                          <EndorsementsGallerySwitcherTextBox
                            setIndex={setIndex}
                          >
                            <EndorsementsGallerySwitcherNameText
                              h={3}
                              hideTopArrow={true}
                              setIndex={setIndex}
                            >
                              {endorsement.name}
                            </EndorsementsGallerySwitcherNameText>
                            <EndorsementsGallerySwitcherDescText
                              setIndex={setIndex}
                            >
                              {endorsement.desc}
                            </EndorsementsGallerySwitcherDescText>
                          </EndorsementsGallerySwitcherTextBox>
                        </EndorsementsGallerySwitcherItemContainer>
                      </a>
                    </EndorsementsGallerySwitcherThumbnailContainer>
                  )
                )}
                {setIndex === 1 && !mobileView ? (
                  <EndorsementsGallerySwitcherThumbnailContainer
                    mobileView={mobileView}
                    setIndex={setIndex}
                  />
                ) : (
                  <></>
                )}
              </EndorsementsGallerySwitcherSectionContents>
            </EndorsementsGallerySwitcherSection>
          ))}
        </EndorsementsGallerySwitcher>
      </EndorsementsGalleryContainer>
    </EndorsementsGalleryWrapper>
  );
};
