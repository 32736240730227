import styled from 'styled-components'
import ResourcesSection from './ResourcesSection'


export const ResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const ResourceInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`



export default ResourcesSection