import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Headline from './Headline';
import TextBody from './TextBody';
import BodyPanelContainer from '../BodyPanelContainer';

const TextPanelBodyContainer = styled(BodyPanelContainer)``;

const TextPanelContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  color: ${(props) => props.theme.colors[props.textColor] || 'black'};
  padding-left: 10px;
  /* padding-top: 1px; */
  padding-bottom: 1px;
  padding-right: 10px;
`;

function renderParagraphs(body) {
  const output = [];

  body.forEach((paragraph, i) => {
    let lines = paragraph.split('।')
    if (typeof lines === 'object' && lines.length > 1 && lines !== null) {
      lines = lines.map((line, n) => {
        if (line === '') {
          return ''
        } else {
          return `${line}${n === lines.length - 1 ? '।' : '।  \n'}`
        }
      })
    }
    output.push(<ReactMarkdown key={'graph' + i}>{lines.join('')}</ReactMarkdown>);
  });

  return output;
}

const TextPanel = ({
  headline,
  anchorName,
  body,
  textColor,
  backgroundColor,
  hideHeadlineArrow,
  headlineShadowColor,
  headlineColor,
  mobileView,
  unifiedBody
}) => {
  return (
    <TextPanelBodyContainer mobileView={mobileView} id={anchorName} unifiedBody={unifiedBody}>
      <TextPanelContainer
        mobileView={mobileView}
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        <Headline
          hideTopArrow={hideHeadlineArrow}
          mobileView={mobileView}
          color={headlineColor}
          shadowColor={headlineShadowColor}
        >
          {headline}
        </Headline>
        <TextBody mobileView={mobileView} color={textColor}>
          {renderParagraphs(body)}
        </TextBody>
      </TextPanelContainer>
    </TextPanelBodyContainer>
  );
};

export default TextPanel;
