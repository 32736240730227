import React from 'react';
import styled from 'styled-components';

const CubeClearBox = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  max-width: ${(props) => props.width}px;
  float: left;
  display: block;
  overflow: hidden;
  cursor: pointer;
`;

const CubeMarginWrapper = styled.div`
  margin-right: 7px;
  margin-bottom: 7px;
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
`;

const CubeContainer = ({ cubeW, cubeH, margin, children, onClick }) => {
  return (
    <CubeClearBox width={cubeW} height={cubeH} onClick={onClick}>
      <CubeMarginWrapper margin={margin}>{children}</CubeMarginWrapper>
    </CubeClearBox>
  );
};

export default CubeContainer;
