import React from 'react'
import ReactMarkdown from 'react-markdown'

export function renderParagraphs(body) {
  const output = [];

  body.forEach((paragraph, i) => {
    let lines = paragraph.split('।')
    if (typeof lines === 'object' && lines.length > 1 && lines !== null) {
      lines = lines.map((line, n) => {
        if (line === '') {
          return ''
        } else {
          return `${line}${n === lines.length - 1 ? '।' : '।  \n'}`
        }
      })
    }
    output.push(<ReactMarkdown key={'graph' + i}>{lines.join('')}</ReactMarkdown>);
  });

  return output;
}

export default renderParagraphs