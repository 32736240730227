import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from 'styled-components';
// import ReactMarkdown from 'react-markdown';
import BodyPanelContainer from '../BodyPanelContainer';

const IssuesBriefBoxesBodyContainer = styled(BodyPanelContainer)`
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 30px;
`

const IssuesBriefBoxesContainer = styled.div`
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  @media (min-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 549px) {
    grid-template-columns: 1fr;
  }
  row-gap: 40px;
  column-gap: 40px;
  justify-items: stretch;
  justify-content: center;
  background-color: ${(props) => props.theme.issuesPanel.backgroundColor};
`;

const IssuesBriefBoxesBoxContainer = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.colors[props.color]};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-radius: 5px;
  box-shadow: lightgray -4px 4px;
  flex-direction: column;
`

const IssuesBriefBoxesHeadline = styled.div`
  text-align: left;
  /* margin-top: auto;
  margin-bottom: auto; */
  font-size: 24px;
  font-family: ${p => p.theme.fontFamilies.subHeader};
`;

const IssuesBriefLinkButtonContainer = styled.div`
  width: 100%;
`

const IssuesBriefLinkButton = styled(AnchorLink)`

`;

const IssuesBriefIntro = styled.div`

`

const DownArrowButton = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"	 viewBox="0 0 980 224">
    <g>
      <path d="M490,145.4L0,0v18.8l490,145.4L980,18.8V0L490,145.4z M0,59.7v18.8L490,224L980,78.5V59.7L490,205.2L0,59.7z"/>
    </g>
  </svg>
)

const IssuesBriefLinkDescription = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
`

const IssuesBriefDownArrowButton = styled(DownArrowButton)`
  width: 75px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  enable-background: new 0 0 980 224;
  fill: ${p => p.theme.colors.jiarEggshell};
`;

// When platform is expanded, these will be needed 
//
// const IssuesBriefBoxesListItem = styled.li``;

// const IssuesBriefBoxesListItemMarkdownSpan = styled(ReactMarkdown)`
//   > p {
//     margin-top: 1px;
//     line-height: ${(props) => (props.mobileView ? '20px' : '22px')};
//   }
// `;


// title, color, intro, listItems, mobileView

const IssueBriefBoxesPanel = ({ issues, mobileView }) => {
  return (
    <IssuesBriefBoxesBodyContainer>
      <IssuesBriefBoxesContainer>
        {issues.map(issue => (
          <IssuesBriefBoxesBoxContainer color={issue.color}>
            <IssuesBriefBoxesHeadline hideTopArrow={true} h={3} mobileView={mobileView}>
              {issue.title.toUpperCase()}
            </IssuesBriefBoxesHeadline>
            <IssuesBriefIntro>
              <p>
                {issue.intro}
              </p>
            </IssuesBriefIntro>
            <IssuesBriefLinkButtonContainer>
              <IssuesBriefLinkButton href={`#${issue.slug}`}>
                <IssuesBriefLinkDescription>
                  More
                </IssuesBriefLinkDescription>
                <IssuesBriefDownArrowButton />
              </IssuesBriefLinkButton>
            </IssuesBriefLinkButtonContainer>
          </IssuesBriefBoxesBoxContainer>
        ))}
      </IssuesBriefBoxesContainer>
    </IssuesBriefBoxesBodyContainer>

  );
};

export default IssueBriefBoxesPanel;
