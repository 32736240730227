import React from 'react'
import styled from 'styled-components'

const CubeImageWrapper = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: inline-block;
    cursor: default;
    border-bottom: none;
    background: transparent;
    vertical-align: middle;
    cursor: pointer;
`

const ImageWrapper = ({height, width, children}) => {


    return (
        <CubeImageWrapper height={height} width={width}>
            {children}
        </CubeImageWrapper>
    )
}

export default ImageWrapper