import React from 'react'
import ContentPanel from '../../_Containers/ContentPanel'
import { generateSections } from '../../_generators'

// cardProps: 
//  borderColor
//  borderWidth
//  borderRadius
//  mobileBackgroundColor
//  backgroundColor
//  fitContent

export const FullCardWithChildren = (props) => {
  // console.log(props)
  const {cardProps, childComponents, mobileView} = props
  return (<ContentPanel {...cardProps} mobileView={mobileView}>
    {generateSections(childComponents, mobileView)}
  </ContentPanel>)
}
export default FullCardWithChildren