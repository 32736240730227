import React, { useMemo } from 'react'
import styled from 'styled-components'
import Headline from './Headline';
import { useAPI } from 'react-api-hooks';
import { DateTime } from 'luxon';
import SquatButton from '../_common/SquatButton';
import { SimpleSpinner } from '../_common/SimpleSpinner';

export const MobilizeEventsListContainer = styled.div`
  display: flex;
  flex-direction: column;

`

export const EventsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`

export const MobilizeEventsSignupCardContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  width: 100%;
  margin-left: 0;
  justify-content: space-between;

  @media (max-width: 760px) {
    width: 100%;
  }
  @media (min-width: 761px) {
    width: 60%;
  }
`;

export const MobilizeEventsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
`

export const MobilizeEventsHeadline = styled(Headline)`
  color: ${p => p.theme.colors[p.color] || 'black'};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: ${p => p.fontSize};
  
`

export const MobilizeEventsSubHeadline = styled(Headline)`
  margin-top: 10px;
  color: ${p => p.theme.colors[p.color] || 'black'};
  text-align: center;
  
  line-height: 1em;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 760px) {
    font-size: 1.3em;
  }
  @media (min-width: 761px) {
    font-size: 1.5em;
  }
`

export const MobilizeEventsDateTimeText = styled(Headline)`
  margin-top: 5px;
  color: ${p => p.theme.colors[p.color] || 'black'};
  text-align: center;
  line-height: 1em;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 760px) {
    font-size: 1.22em;
  }
  @media (min-width: 761px) {
    font-size: 1.4em;
  }

`


export const MobilizeEventsImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const MobilizeEventsSignupImage = styled.img`
  max-width: 60%;
  height: auto;
  object-fit: contain;
`

export const MobilizeEventsLogo = styled.img`
  max-width: 30%;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  
`

const MobilizeEventPanel = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  border-bottom: 4px dotted ${p => p.theme.colors.lightgray};
  border-left: 4px dotted ${p => p.theme.colors.lightgray};
  border-right: 4px dotted ${p => p.theme.colors.lightgray};

  &:first-of-type {
    border-top: 4px dotted ${p => p.theme.colors.lightgray};
    margin-top: 10px;
  }
  
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    flex-direction: column-reverse;
  }
`

const MobilizeEventImageContainer = styled.div`
  margin-right: 0;
  margin-left: auto;
  max-width: 60%;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
`

const MobilizeEventImage = styled.img`
  max-width: 100%;


`

const MobilizeEventDetailsContainer = styled.div`
  margin-right: 5px;

`

export const MobilizeEventDetailsButton = styled(SquatButton).attrs(p => ({
  ...p,
  backgroundColor: 'jiarTeal',
  color: 'black',
  hoverBorderColor: 'jiarPurple',
  hoverColor: "jiarEggshell"
}))`
  /* font-family: ${(props) => props.theme.fontFamilies.links};
  border-radius: 10px;
  margin-left: auto;
  cursor: pointer !important;
  letter-spacing: -0.5px; */

  /* font-size: 20px;
  line-height: 22px; */
  margin-bottom: 4px;
  margin-left: -1px;

  @media(max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    margin-top: 4px;
    font-size: 14px;
    line-height: 16px;
  }
`

const MobilizeEventDate = styled(Headline).attrs(() => ({
  h: 3,
  hideTopArrow: true
}))`
  margin-top: 0px;
  margin-left: 0.5px;
  margin-bottom: 0px;
  color: ${p => p.theme.colors.jiarTeal};

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 1em;
    margin-top: 2px;
  }

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {

  }
`

const MobilizeEventHeadline = styled(Headline).attrs(() => ({
  h: 2
}))`
  margin-top: 5px;
  line-height: 27px;
  margin-bottom: 8px;
  font-size: 1.2em;
  line-height: 1.2em;

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 1.1em;
    line-height: 1.1em;
    margin-top: 2px;
    margin-bottom: 4px;
  }
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {
    margin-top: 2px;
  }
  
`
const MobilizeEventLocation = styled(Headline).attrs(() => ({
  h: 1,
  hideTopArrow: true
}))`
  font-family: ${p => p.theme.fontFamilies.paragraph};
  text-transform: unset;
  margin-top: 0px;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 10px;

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 0.9em;
    line-height: 1em;
    margin-bottom: 4px;
    margin-top: 2px;
  }
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {
    margin-top: 0px;
  }

`

export const MobilizeEventsList = (props) => {
// eslint-disable-next-line no-unused-vars
  const { response=[], _error, _isLoading } = useAPI('https://api.mobilize.us/v1/organizations/6341/events?per_page=1000')

  let eventsByTimeslot = useMemo(() => {

    let events = []

    if (response && response.data && response.data.data) {
      response.data.data.forEach(event => {
        event.timeslots.forEach(timeslot => {
          events.push([timeslot.start_date, event])
        })
      })


      events = events.filter(e => e[0] > (new Date()).getTime() / 1000)
      events.sort((a,b) => a[0] - b[0])
      return events
    } else {
      return null
    }
  }, [response])


  function renderLocation(location, isVirtual) {
    return location ?
            location.address_lines.filter(line => line.length > 0 ? true : false).concat([location.locality ? location.locality : ""]).join(", ") : isVirtual ? 'Virtual event - Join from anywhere' : 'Click below for location'

  }

  return (
    <MobilizeEventsListContainer>
      <Headline hideTopArrow={true}>
        Events
      </Headline>
      <EventsListContainer>
        { eventsByTimeslot ? eventsByTimeslot.map((event, i) => {
          return (
            <MobilizeEventPanel key={event[1].id+i+Math.random()}>
              <MobilizeEventDetailsContainer>
                <MobilizeEventDate>{DateTime.fromSeconds(event[0]).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</MobilizeEventDate>
                  <MobilizeEventHeadline hideTopArrow={true} h={3}>{event[1].title}</MobilizeEventHeadline>
                  <MobilizeEventLocation>{renderLocation(event[1].location, event[1].is_virtual)}</MobilizeEventLocation>
                <a href={event[1].browser_url}><MobilizeEventDetailsButton>See details ➞</MobilizeEventDetailsButton></a>
              </MobilizeEventDetailsContainer>
              <MobilizeEventImageContainer>
                <MobilizeEventImage src={event[1].featured_image_url}/>
              </MobilizeEventImageContainer>
            </MobilizeEventPanel>
          )
        }) : (
          <SimpleSpinner/>
        )}
      </EventsListContainer>
    </MobilizeEventsListContainer>
  )

}

export default MobilizeEventsList