import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import Headline from './Headline';
import AssetsContext from '../assetsContext';
import { ModalContext } from '../modalContext';
import BackgroundImage from 'gatsby-background-image'
import { generateModalViews, generateModalThumbs } from './StoryGallery/StoryGallery'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const GalleryLauncherContainer = styled.div`
  padding-left: 20px;
  height: 115px;
`

const GalleryLauncherTextContainer = styled.div`
  z-index: 20;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`

const GalleryImageLayer = styled(BackgroundImage)`
    width: 100%;
    height: 100%;
    background-position: right;
    background-size: cover;
    :after {
      border-radius: 20px; 
      box-shadow: -3px 3px ${p => p.theme.colors.jiarTeal};
    }
    :before {
      border-radius: 20px;
      box-shadow: -3px 3px ${p => p.theme.colors.jiarTeal};
    }
`

const GalleryLauncherTitle = styled(Headline).attrs(p => ({h: 3}))`
  padding-top: 5px;
  margin-top: 4px;
  margin-bottom: 5px;
  z-index: 20;
  max-width: 100%;

  font-size: 20px;
  line-height: 20px;
  margin-bottom: 4px;
`

const GalleryLauncherHeadline = styled(Headline).attrs(p => ({h: 2}))`
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 20;
  max-width: 100%;

  font-size: 25px;
  line-height: 25px;
  margin-bottom: 4px;

  @media (max-width: 330px) {
    max-width: 200px;
    font-size: 24px;
    line-height: 24px;
  }
`

const GalleryLauncherSubheadline = styled(Headline).attrs(p => ({h: 3}))`
  z-index: 20;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  
  &:hover {
    color: ${p => p.hoverColor};
  }
`

const GalleryPreviewPane = styled.div`
  position: absolute;
  left: 230px;
  max-width: calc(100% - 480px);
  top: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  z-index: 900;
  mask-image: linear-gradient(to right, black 95%, transparent 100%);
  > img {
    max-height: 100px;
    margin: 0px 4px 0px 4px;
    border: 1px solid lightgray;
  }
`

const GalleryLauncher = ({config, title, subTitle, callToLaunch, slides, mobileView}) => {

  let { backgroundImageName } = config
  const {
    imagesWidenedByName,
    imagesHeightenedByName,
    imagesMaxByName,
  } = useContext(AssetsContext);
  const { width, height } = useWindowDimensions ();

  let windowWidth = width;
  let windowHeight = height;

  // eslint-disable-next-line no-unused-vars
  // const { zoomOnClick, backgroundColor } = config;
  // const { text, titleColor, titleShadowColor } = title;

  // const [scrollbarWidth, setScrollbarWidth] = useState(0);



  let backgroundImageSrc = imagesMaxByName[backgroundImageName]
  // let backgroundImageSrc = imagesMaxFixedByName[backgroundImageName].src

  const {initContent} = useContext(ModalContext);

  let modalViews = useMemo(() => generateModalViews({cubes: slides, windowH: windowHeight, windowW: windowWidth, imagesMaxByName}), [slides, windowHeight, windowWidth, imagesMaxByName])
  let modalThumbs = useMemo(() => generateModalThumbs({cubes: slides, imagesWidenedByName, imagesHeightenedByName}), [slides, imagesWidenedByName, imagesHeightenedByName])

  const openGallery = () => {
    initContent(modalViews, modalThumbs, 0)
  }


  return (
      <GalleryImageLayer Tag="div" fluid={backgroundImageSrc} alt={'sandy explaining ranked-choice voting'}>
        <GalleryLauncherContainer onClick={openGallery} >
          <GalleryLauncherTextContainer >
            <GalleryLauncherTitle color={title.color} hideTopArrow={true}>
              {title.text}
            </GalleryLauncherTitle>
            <GalleryLauncherHeadline color={subTitle.color} hideTopArrow={true}>
              {subTitle.text}
            </GalleryLauncherHeadline>
            <GalleryLauncherSubheadline hoverColor={callToLaunch.hoverColor} color={callToLaunch.color} hideTopArrow={true}>
              {callToLaunch.text} <span style={{fontWeight: 'bold'}}>→</span>
            </GalleryLauncherSubheadline>
          </GalleryLauncherTextContainer>
          <GalleryPreviewPane>
            {modalThumbs}
          </GalleryPreviewPane>
        </GalleryLauncherContainer>
      </GalleryImageLayer>
  )

}

export default GalleryLauncher