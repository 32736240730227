let loadFirebaseDependencies;

if (typeof window !== 'undefined') {
  const app = import('firebase/app');
  const analytics = import('firebase/analytics')
  const auth = import('firebase/auth');
  const database = import('firebase/firestore');
  const functions = import('firebase/functions');
  const storage = import('firebase/storage');

  loadFirebaseDependencies = Promise.all([
    app,
    analytics,
    auth,
    database,
    functions,
    storage,
  ]).then((values) => {
    return values[0];
  });
}

export default loadFirebaseDependencies;
