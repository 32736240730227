import styled from 'styled-components'
import Headline from '../BodyComponents/Headline'

export const TightH1 = styled(Headline).attrs(p => ({...p, hideTopArrow: true, h: 1}))`
  /* h1 {
    color: ${p => p.color || 'black'};
  } */
  margin-left: 0;
  margin-right: auto;
  text-align: left;
  margin-top: 0px;
  margin-block-start: 0em;
  @media (min-width: 760px) {
    font-size: ${p => p.textSize ? p.textSize + 'px': '1.3em'};
    line-height: ${p => p.textSize ? (p.textSize - 0.1*p.textSize) + 'px' : '1.0em'};
    margin-top: 0px;
    margin-block-start: 0em;
    padding-top: 0px;
    padding-left: 0px;

  }
  @media (max-width: 761px) {
    font-size: ${p => p.mobileTextSize ? p.mobileTextSize + 'px' : '1.1em'};
    line-height: ${p => p.textSize ? (p.textSize - 0.1*p.textSize) + 'px' : '1.0em'};
    padding-left: 0px;
  }
  
`

export default TightH1