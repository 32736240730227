import React from 'react'
import styled from 'styled-components'

export const ContentPanelOuter = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  width: ${p => p.type ? 
                  p.type === 'halves' ? 
                  49.4 :
                  p.type === 'thirds' ? 33.2 : 
                  100 : 100}%;
  /* margin-right: 10px; */
  border-radius: 10px;
  border-width: ${p => p.borderWidth ? p.borderWidth : 0}px;
  border-color: ${p => p.borderColor ? p.theme.colors[p.borderColor] : 'none'};
  border-style: ${p => p.borderWidth > 0 ? 'solid' : 'none'};
  display: flex;
  flex-direction: ${p => p.flexDirection ? p.flexDirection : 'row'};
  align-items: start;
  justify-content: center;
    box-sizing: border-box;
  padding: 10px;

  &:nth-of-type(even) {
      margin-right: 0px;
    }

  &:nth-of-type(3){
    margin-right: 0px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 1.22em;
    width: 100%;
    ${p => p.mobileBackgroundColor ? `
      background-color: ${p.theme.colors[p.mobileBackgroundColor]};
    `:``}
    ${p => p.mobileBorderColor ? `
      border-color: ${p.theme.colors[p.mobileBorderColor]};
    `:``}
  }

  ${p => p.linkColors ? `
  a {

    ${p.linkColors.unvisited ? `
      color: ${p.theme.colors[p.linkColors.unvisited]};
    `:``}

    ${p.linkColors.visited ? `
      &:visited {
        color: ${p.theme.colors[p.linkColors.visited]};
      }
    `:``}

    ${p.linkColors.hover ? `
      &:hover {
        color: ${p.theme.colors[p.linkColors.hover]};
      }
    `:``}
  }
    
  
  `:``}

  ${p => p.fitContent && `
    height: fit-content;
  `}
`

export const ContentPanelInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${p => p.fitContent ? 0 : 5}px;
  /* max-width: 50%;
  min-width: 45%; */
  margin-left: 0;
  margin-right: auto;
  width: 100%;
  @media (max-width: 849px) {
    max-width: unset;
    min-width: unset;
  }
  @media (min-width: 850px) {
    max-width: unset;
    min-width: unset;
  }
`

export const ContentPanel = ({type, ...props}) => (
  <ContentPanelOuter className={props.className} {...props}>
    <ContentPanelInner fitContent={props.fitContent}>
      {props.children}
    </ContentPanelInner>
  </ContentPanelOuter>
)

export default ContentPanel