import React from 'react'
import styled from 'styled-components'
import {LinkIcon} from '../../_common'
import {ResourceContainer} from '.'

const LinkResourceIcon = styled(LinkIcon)`
height: 20px;
margin-top: auto;
margin-bottom: -2px;
margin-right: 5px;
fill: ${p => p.theme.colors.jiarSalmon};
`

const LinkResource = ({ title, url, className }) => (
  <ResourceContainer className={className}>
    <a href={url} target={'_blank'} rel={'noreferrer'}>
      <LinkResourceIcon />
      <span>{title}</span>
    </a>
  </ResourceContainer>
);

export default LinkResource