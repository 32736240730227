import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AssetsContext from '../assetsContext';
import BodyPanelContainer from '../BodyPanelContainer';
import Headline from './Headline';

import {
  SubmitSpinner
} from '../HeaderComponents/JoinCampaignWidget';
import { useFirebase } from '../Firebase';

export const MeetSandySignupWidgetContainer = styled(BodyPanelContainer)`
  display: flex;
  @media (max-width: 760px) {
    flex-direction: column;
  }
  @media (min-width: 761px) {
    flex-direction: row;
  }
`;

export const MeetSandySignupCardContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  width: 100%;
  margin-left: 0;
  justify-content: space-between;

  @media (max-width: 760px) {
    width: 100%;
  }
  @media (min-width: 761px) {
    width: 60%;
  }
`;

export const MeetSandyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
`

export const MeetSandyHeadline = styled(Headline)`
  color: ${p => p.theme.colors[p.color] || 'black'};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: ${p => p.fontSize};
  
`

export const MeetSandySubHeadline = styled(Headline)`
  margin-top: 10px;
  color: ${p => p.theme.colors[p.color] || 'black'};
  text-align: center;
  
  line-height: 1em;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 760px) {
    font-size: 1.3em;
  }
  @media (min-width: 761px) {
    font-size: 1.5em;
  }
`

export const MeetSandyDateTimeText = styled(Headline)`
  margin-top: 5px;
  color: ${p => p.theme.colors[p.color] || 'black'};
  text-align: center;
  line-height: 1em;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 760px) {
    font-size: 1.22em;
  }
  @media (min-width: 761px) {
    font-size: 1.4em;
  }

`

export const MeetSandyImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const MeetSandySignupImage = styled.img`
  max-width: 60%;
  height: auto;
  object-fit: contain;
`

export const MeetSandyLogo = styled.img`
  max-width: 30%;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  
`

export const MeetSandySignupFormSectionContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  @media (max-width: 760px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 761px) {
    width: 40%;
    padding-left: 20px;
  }
  position: relative;
`

export const MeetSandySignupFormDescription = styled.div`

`

export const MeetSandyFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: auto;


  @media(max-width: 760px) {
    flex-direction: column;
  }

  @media(min-width: 761px) {
    flex-direction: column;
    padding-right: 10px;
  }

`

export const MeetSandyFormItemsContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 760px) {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 6px;
    margin-top: 10px;
    flex-direction: row;
    /* > div:fir  Zst-of-type {
      padding-top: 2px;
      > input {
        margin: 0px 3px 3px 3px;
      }

    } */
  }

  @media (min-width: 761px) {
    padding-top: 0px;
    padding-left: 0;
    padding-right: 0;
    margin-top: auto;
    flex-direction: row;
  }
`

export const MeetSandyForm = styled.form`
  font-family: ${(props) => props.theme.fontFamilies.subHeader};
  font-weight: normal;
`

export const MeetSandyFieldContainer = styled.div`
  width: 100%;
  @media(max-width: 760px) {
    padding-bottom: 5px;
    margin-left: 0px;
    margin-right: 10px;
    &:only-child {
      > input {
        width: 100%;
      }
    }
    &:last-child {
      > input {
        width: 100%;
      }
    }
  }

  @media(min-width: 761px) {
    padding-bottom: 5px;
    &:first-child {
      padding-right: 10px;
    }

    &:only-child {
      padding-right: 0px;
      > input {
        width: 100%;
      }
    }
    &:last-child {
      > input {
        width: 100%;
      }
    }
  }
`

export const MeetSandyField = styled.input`
  border-radius: 3px;
  font-family: ${p => p.theme.fontFamilies.paragraph};
  font-stretch: 100%;
  /* font-variant: ${p => p.content.length > 0 && 'small-caps'}; */
  font-weight: normal;
  letter-spacing: -0px;

  color: black;

  @media(max-width: 760px) {
    border-color: lightgray;
    width: 90%;
    font-size: 14px;
    line-height: 14px;
    padding-left: 5px;


  }

  @media(min-width: 761px) {
    border-color: lightgray;
    line-height: 14px;
    width: 90%;
    font-size: 14px;
    padding: 2px 5px;
    margin: 3px 10px 0px 0px;
  }

  ::placeholder {
    color: darkgray;
    opacity: 1;

    @media(max-width: 760px) {
      line-height: 18px;
    }

    @media(min-width: 761px) {
      line-height: 14px;
    }

  }

`

export const MeetSandyFieldLabel = styled.label`
  padding-left: 4px;
  margin-top: 0px;
  padding-top: 0px;
  font-family: ${p => p.theme.fontFamilies.paragraph};
  @media(max-width: 760px) {
    font-size: 12px;
    line-height: 12px;
  }

  @media(min-width: 761px) {
    font-size: 12px;
    line-height: 12px;
  }

`

export const MeetSandySubmitButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: inline-flex;

  @media(max-width: 760px) {
    margin-top: 5px;
  }

  @media(min-width: 761px) {
    margin-top: 10px;
  }
`

export const MeetSandySubmitButton = styled.button`
  font-family: ${(props) => props.theme.fontFamilies.header};
  border-radius: 3px;
  margin-left: auto;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer !important;
  letter-spacing: -0.5px;

  @media(max-width: 760px) {
    float: left;
    height: 20px;
    font-size: 16px;
    line-height: 16px;
    border: solid ${p => p.theme.links.default} 1px;
    width: 80px;
    margin-right: auto;  
  }

  @media(min-width: 761px) {
    float: right;
    height: 30px;
    font-size: 22px;
    line-height: 22px;
    border: solid ${p => p.theme.links.default} 1px;
    width: 100px;
  }
`

export const Required = styled.span`
    color: red;
`

export const RequiredIndicator = () => (<Required>*</Required>)

export const RequiredKeyContainer = styled.p`
  margin-bottom: 0;
  margin-right: 0;
  margin-left: auto;
  @media(max-width: 760px) {
    font-size: 12px;
  }

  @media(min-width: 761px) {
    font-size: 12px;
  }
`

export const RequiredKey = ({text}) => (<RequiredKeyContainer><Required>*</Required><span> = {text}</span></RequiredKeyContainer>)

export const MeetSandySignupWidget = ({
  headline,
  headlineFontSize,
  imageName,
  logoName,
  subHeadline,
  dateTimeText,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  streetAddress1Label,
  streetAddress2Label,
  cityLabel,
  phoneLabel,
  backgroundColor,
  headlineColor,
  subHeadlineColor,
  dateTimeColor,
  signupFormHeadlineText,
  signupFormDescription,
  submitText,
  submitSuccessText,
  submitErrorText,
  signupFormRequired,
  mobileView,
  body
}) => {

  const files = useContext(AssetsContext).fileUrls;

  const [firstNameState, setFirstNameState] = useState('');
  const [lastNameState, setLastNameState] = useState('');
  const [emailState, setEmailState] = useState('');

  const [submitError, setSubmitError] = useState(false);

  const { firebase } = useFirebase();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleTextInput(e, field) {
    setSuccess(false);
    switch (field) {
      case 'firstName':
        setFirstNameState(e.target.value);
        break;
      case 'lastName':
        setLastNameState(e.target.value);
        break;
      case 'email':
        setEmailState(e.target.value);
        break;
      default:
        break;
    }
  }

  
  function clearFormValues() {
    setFirstNameState('');
    setLastNameState('');
    setEmailState('');
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (success) {
      clearFormValues();
      setSuccess(false);
    } else {
      setLoading(true);
      firebase
        .submitMeetSandySignup({
          firstName: firstNameState,
          lastName: lastNameState,
          email: emailState
        })
        .then((response, error) => {
          if (!error) {
            registerSuccess();
          } else {
            registerError();
          }
          //   console.log(response);
        });
    }
  }

  function registerSuccess() {
    setSuccess(true);
    setLoading(false);
  }

  function registerError() {
    setSuccess(false);
    setLoading(false);
    setSubmitError(true);
  }


  return (
    <MeetSandySignupWidgetContainer>
      <MeetSandySignupCardContainer backgroundColor={backgroundColor}>
        <MeetSandyTextContainer>
          <MeetSandyHeadline color={headlineColor} hideTopArrow={true} fontSize={headlineFontSize}>
            {headline}
          </MeetSandyHeadline>
          <MeetSandySubHeadline color={subHeadlineColor} hideTopArrow={true}>
            {subHeadline}
          </MeetSandySubHeadline>
          <MeetSandyDateTimeText color={dateTimeColor} hideTopArrow={true}>
            {dateTimeText[0]}
          </MeetSandyDateTimeText>
          <MeetSandyDateTimeText color={dateTimeColor} hideTopArrow={true}>
            {dateTimeText[1]}
          </MeetSandyDateTimeText>
        </MeetSandyTextContainer>
        <MeetSandyImagesContainer>
          <MeetSandySignupImage src={files[imageName]}></MeetSandySignupImage>
          <MeetSandyLogo src={files[logoName]}></MeetSandyLogo>
        </MeetSandyImagesContainer>
      </MeetSandySignupCardContainer>
      <MeetSandySignupFormSectionContainer>
        {/* <MeetSandySignupFormTitle hideTopArrow={true}>
          {signupFormHeadlineText}
        </MeetSandySignupFormTitle> */}
        <MeetSandySignupFormDescription>
          <p>{signupFormDescription}</p>
        </MeetSandySignupFormDescription>
        <MeetSandyForm onSubmit={handleSubmit}>
          <RequiredKey text={signupFormRequired}/>
          <MeetSandyFormContainer>
            <MeetSandyFormItemsContainer>
              <MeetSandyFieldContainer 
                placeholder={firstNameLabel}
                key={'firstName'}
              >
                <MeetSandyField 
                  content={firstNameState}
                  onChange={e => handleTextInput(e, 'firstName')}
                  type="text"
                  // placeholder={firstNameLabel}
                  key={'firstName'}
                />
                <MeetSandyFieldLabel>
                  {firstNameLabel} <RequiredIndicator/>
                </MeetSandyFieldLabel>
              </MeetSandyFieldContainer>
              <MeetSandyFieldContainer 
                placeholder={lastNameLabel}
                key={'lastName'}
              >
                <MeetSandyField 
                  content={lastNameState}
                  onChange={e => handleTextInput(e, 'lastName')}
                  type="text"
                  // placeholder={lastNameLabel}
                  key={'lastName'}
                />
                <MeetSandyFieldLabel>
                  {lastNameLabel} <RequiredIndicator/>
                </MeetSandyFieldLabel>
              </MeetSandyFieldContainer>
            </MeetSandyFormItemsContainer>
            <MeetSandyFormItemsContainer>
              <MeetSandyFieldContainer 
                placeholder={emailLabel}
                key={'email'}
              >
                <MeetSandyField 
                  content={emailState}
                  onChange={e => handleTextInput(e, 'email')}
                  type="text"
                  // placeholder={emailLabel}
                  key={'email'}
                />
                <MeetSandyFieldLabel>
                  {emailLabel} <RequiredIndicator/>
                </MeetSandyFieldLabel>
              </MeetSandyFieldContainer>
            </MeetSandyFormItemsContainer>
          </MeetSandyFormContainer>
          <MeetSandySubmitButtonContainer>
            <MeetSandySubmitButton>
              {!loading && !success && !submitError && submitText}
              {loading && <SubmitSpinner/>}
              {success && submitSuccessText }
              {submitError && submitErrorText }
            </MeetSandySubmitButton>
          </MeetSandySubmitButtonContainer>
        </MeetSandyForm>
      </MeetSandySignupFormSectionContainer>
    </MeetSandySignupWidgetContainer>
  )
};


export default MeetSandySignupWidget