import React, { useContext } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image/withIEPolyfill';
import Headline from './Headline';
import TextBody from './TextBody';
import BodyPanelContainer from '../BodyPanelContainer';
import AssetsContext from '../assetsContext';

const IntroPanelWithInsetGalleryBodyContainer = styled(BodyPanelContainer)``;

const IntroPanelWithInsetGalleryContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  color: ${(props) => props.theme.colors[props.textColor] || 'black'};
  /* padding-top: 1px; */
  padding-bottom: 1px;
  display: flex;
  flex-direction: column;
`;

const IntroPanelInnerBodyContainer = styled.div`
  display: flex;
  flex-direction: ${p => p.mobileView ? 'column' : 'row'};
`

const InsetGalleryOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  div {
    h3 {
      margin: 5px 0 0px 0;
    }
  }
`

const InsetGalleryContainer = styled.div`
  display: flex;
  flex-direction: ${p => p.mobileView ? 'column' : 'row'};
 
`

const InsetGalleryPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.backgroundColor};
  padding: 10px;
  margin-top: 10px;
  margin-right: ${p => p.mobileView ? 'auto' : '10px'};
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: ${p => p.mobileView ? '100%' : '33%'};

  &:last-of-type {
    margin-right: 0px;
  }
`

const InsetGalleryNumber = styled.div`
  width: 100%;
  font-family: ${p => p.theme.fontFamilies.header};


  div {
    font-size: 30px;
    text-align: center;
    width: 37px;
    height: 37px;
    background-color: darkgray;
    color: ${p => p.theme.colors.jiarEggshell};
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
  }

`

const InsetGalleryKeyContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`
const InsetGalleryImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InsetGalleryImage = styled(Img)`
  border-radius: 5px;
  object-fit: cover;
  width: 150px;
  height: 150px;
  margin-top: auto;
  margin-bottom: auto;
  object-position: ${(props) => (props.mobileView ? '' : 'right')};
`

const InsetGalleryCaption = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-family: ${p => p.theme.fontFamilies.paragraph};
  font-size: 15px;

  div {
    padding: 8px;
    margin: auto;
  }
`

const InsetGallery = ({panels, direction, textColor, mobileView}) => {

  const { headline, imageSets } = panels;

  const imagesMaxByName = useContext(AssetsContext).imagesMaxByName

  return (
    <InsetGalleryOuterContainer>
      <Headline 
        hideTopArrow={true}
        mobileView={mobileView}
        h={3}
      >
        {headline}
      </Headline>
      <InsetGalleryContainer mobileView={mobileView}>
        {
          imageSets.map((imageSet, i) => (
            <InsetGalleryPanelContainer key={'insetpanel' + i} backgroundColor={imageSet.backgroundColor} mobileView={mobileView}>
              <InsetGalleryKeyContainer>
                <InsetGalleryNumber><div>{i + 1}</div></InsetGalleryNumber>
                <InsetGalleryCaption>
                  <div>
                    {imageSet.caption}
                  </div>
                </InsetGalleryCaption>
              </InsetGalleryKeyContainer>
              <InsetGalleryImageContainer >
                <InsetGalleryImage fluid={imagesMaxByName[imageSet.imageName]} imgStyle={{ maxWidth: 150, height: 150, objectFit: 'cover', borderRadius: 5 }} />
              </InsetGalleryImageContainer>
            </InsetGalleryPanelContainer>

          ))
        }
      </InsetGalleryContainer>
    </InsetGalleryOuterContainer>
    
  )


}

function renderParagraphs({body, panels, mobileView}) {
  const output = [];

  body.forEach((paragraph, i) => {
    if (panels.showAfterBodyGraphIndex === (i - 1)) {
      output.push(<InsetGallery mobileView={mobileView} panels={panels} key={'insetgallery'}/>)
    }
    let lines = paragraph.split('।')
    if (typeof lines === 'object' && lines.length > 1 && lines !== null) {
      lines = lines.map((line, n) => {
        if (line === '') {
          return ''
        } else {
          return `${line}${n === lines.length - 1 ? '।' : '।  \n'}`
        }
      })
    }
    output.push(<ReactMarkdown key={'graph' + i}>{lines.join('')}</ReactMarkdown>);
  });

  return output;
}

const IntroPanelWithInsetGallery = ({
  headline,
  anchorName,
  body,
  textColor,
  panels,
  backgroundColor,
  hideHeadlineArrow,
  headlineShadowColor,
  headlineColor,
  mobileView,
}) => {
  return (
    <IntroPanelWithInsetGalleryBodyContainer mobileView={mobileView} id={anchorName}>
      <IntroPanelWithInsetGalleryContainer
        mobileView={mobileView}
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        <Headline
          hideTopArrow={hideHeadlineArrow}
          mobileView={mobileView}
          color={headlineColor}
          shadowColor={headlineShadowColor}
        >
          {headline}
        </Headline>
        <IntroPanelInnerBodyContainer mobileView={mobileView}>
          <TextBody mobileView={mobileView} color={textColor}>
            {renderParagraphs({ body, panels, mobileView })}
          </TextBody>
          
        </IntroPanelInnerBodyContainer>
      </IntroPanelWithInsetGalleryContainer>
    </IntroPanelWithInsetGalleryBodyContainer>
  );
};

export default IntroPanelWithInsetGallery;
