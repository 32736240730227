/*global google*/
import React, { useContext } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import AssetsContext from '../assetsContext';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import BodyPanelContainer from '../BodyPanelContainer';

import Headline from './Headline';

const MapPanelContainer = styled(BodyPanelContainer)`
  /* padding-left: 15px;
  padding-right: 15px; */
`;

const MapContainer = styled.div`
  width: 100%;
  height: 500px;
  padding-top: ${(props) => (props.mobileView ? '20px' : '20px')};

  @media (max-width: 640px) {
    height: 300px;
  }
`;

const Map = ({ districtMapKml, mapTitle, mobileView }) => {
  const files = useContext(AssetsContext).fileUrls;
  const origin = useLocation().origin;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAhUmylMuGMO3cQHu-V2HYVdUt2CVz7Wb4',
  });

  let containerStyle = {
    height: '100%',
    width: '100%',
  };

  const onLoad = React.useCallback(function onLoad(mapInstance) {
    let url =
      origin.includes('localhost') || origin.includes('127.0.0.1')
        ? `https://www.sandyforcouncil.com/public/district37.kml`
        : origin + files[districtMapKml];
    // eslint-disable-next-line no-unused-vars
    let District = new google.maps.KmlLayer({
      url: url,
      map: mapInstance,
      preserveViewport: false,
    });
  }, [districtMapKml, files, origin]);

  const renderMap = () => {
    return (
      <GoogleMap
        id="districtMap"
        mapContainerStyle={containerStyle}
        zoom={8}
        onLoad={onLoad}
      ></GoogleMap>
    );
  };

  if (loadError) {
    // console.log('loadError:', loadError)
    return <div>Map could not load.</div>;
  }

  return (
    <MapPanelContainer>
      <Headline mobileView={mobileView}>{mapTitle}</Headline>
      <MapContainer mobileView={mobileView}>
        {isLoaded ? renderMap() : <div>'Loading'</div>}
      </MapContainer>
    </MapPanelContainer>
  );
};

export default React.memo(Map);
