import { useEffect, useState } from 'react';
import getFirebaseInstance from './firebase';
import loadFirebaseDependencies from './loadFirebaseDependencies';

function useFirebase() {
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadFirebaseDependencies.then((app) => {
      const firebaseInstance = getFirebaseInstance(app);
      setFirebase(firebaseInstance);
      setLoading(false);
    });
  }, []);

  return { firebase, loading };
}

export default useFirebase;
