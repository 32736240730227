import styled from 'styled-components';
import Img from 'gatsby-image';

const Image = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.scaleDown ? 'scale-down' : 'cover')};
  left: 0px;
  position: ${(props) => (props.uniformWidth ? 'relative' : 'absolute')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export default Image;
