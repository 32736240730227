import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import CustomCheckbox from '../SharedComponents/CustomCheckbox';
import { useFirebase } from '../Firebase';

const JoinCampaignWidgetContainer = styled.div`
  width: ${(props) => (props.mobileView ? '100%' : '350px')};
  height: ${(props) => (props.mobileView ? 'auto' : '100%')};
  right: ${(props) => (props.mobileView ? '0' : '50px')};
  position: ${(props) => (props.mobileView ? 'relative' : 'absolute')};
  /* margin-top: ${(props) => (props.mobileView ? '13px' : '')};
  margin-bottom: ${(props) => (props.mobileView ? '0px' : '')}; */
  z-index: 100;
  top: 0;

  > div {
    padding-top: ${(props) => (props.mobileView ? '20px' : '64px')};
    padding-bottom: ${p => p.mobileView ? '20px' : ''};
    background-color: ${(props) => props.theme.signupWidget.background};
    height: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: ${(props) => (props.mobileView ? '' : 'center')};
    justify-content: center;
    flex-direction: ${(props) => (props.mobileView ? 'column' : 'column')};
  }
`;

const JoinCampaignWidgetContents = styled.div``;

const JoinCampaignLogoContainer = styled.div`
  width: ${(props) => (props.mobileView ? '25%' : '100%')};
  max-width: ${(props) => (props.mobileView ? '25%' : '100%')};
  padding-top: 0px;
  padding-bottom: 15px;
  height: auto;
  align-items: center;
  display: inline-flex;
`;

const JoinCampaignLogo = styled.img`
  width: 290px;
  margin: 0px 20px;
`;

const JoinCampaignTitleContainer = styled.div`
  width: 100%;
  align-items: center;
  display: inline-flex;
  text-align: center;
  height: auto;
  background: ${(props) => props.theme.signupWidget.titleBackground};
`;

const JoinCampaignTitle = styled.span`
  font-size: ${(props) => (props.mobileView === 'small' ? '22px' : '28px')};
  font-family: ${(props) => props.theme.fontFamilies.header};
  color: ${(props) => props.theme.signupWidget.titleColor};
  letter-spacing: -0px;
  text-shadow: ${(props) => (props.mobileView ? '' : '')};
  // : '-1px 1px 2px ' + props.theme.signupWidget.titleShadowColor};
  margin-left: auto;
  margin-right: auto;
`;

export const JoinCampaignFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.mobileView ? 'row' : 'column')};
`;

export const JoinCampaignFormItemsContainer = styled.div`
  width: 90%;
  padding-top: ${(props) => (props.mobileView ? '0px' : '20px')};
  padding-left: ${(props) => (props.mobileView ? '6px' : '0')};
  padding-right: ${(props) => (props.mobileView ? '6px' : '0')};
  margin-top: ${(props) => (props.mobileView ? '10px' : 'auto')};

  ${(props) =>
    props.mobileView &&
    `
      > div:first-of-type {
        padding-top: 2px;
        > input {
          margin: 0px 3px 3px 3px;
        }
      }

  `}
`;

export const JoinCampaignForm = styled.form`
  font-family: ${(props) => props.theme.fontFamilies.subHeader};
  font-weight: normal;
  font-size: ${(props) => (props.mobileView ? '15px' : '20px')};

  label {
    line-height: ${(props) => (props.mobileView ? '20px' : '23px')};
    font-size: ${(props) => (props.mobileView ? '15px' : '18px')};
  }
`;
export const JoinCampaignFieldContainer = styled.div`
  width: 100%;
`;

export const JoinCampaignField = styled.input`
  border: ${(props) => (props.mobileView ? '' : 'none')};
  border-color: ${(props) => (props.mobileView ? 'lightgray' : '')};
  border-radius: 3px;
  width: ${(props) => (props.mobileView ? '90%' : '100%')};
  font-family: ${(props) => props.theme.fontFamilies.subHeader};
  font-size: ${(props) => (props.mobileView ? '15px' : '20px')};
  font-stretch: 100%;
  font-variant: ${(props) => props.content.length > 0 && 'small-caps'};
  font-weight: normal;
  line-height: ${(props) => (props.mobileView ? '15px' : '20px')};
  letter-spacing: -0px;
  padding: ${(props) => (props.mobileView ? '3px 6px' : '0px 9px')};

  margin: 3px;
  color: black;

  ::placeholder {
    color: black;
    opacity: 1;
    line-height: ${(props) => (props.mobileView ? '18px' : '24px')};
  }
`;
export const JoinCampaignSubmitButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: inline-flex;
  margin-top: ${(props) => (props.mobileView ? '5px' : '10px')};
`;
export const JoinCampaignSubmitButton = styled.button`
  float: ${(props) => (props.mobileView ? 'left' : 'right')};
  height: ${(props) => (props.mobileView ? '20px' : '30px')};

  font-family: ${(props) => props.theme.fontFamilies.header};
  font-size: ${(props) => (props.mobileView ? '16px' : '22px')};
  line-height: ${(props) => (props.mobileView ? '16px' : '22px')};
  border: ${(props) =>
    props.mobileView ? `solid ${props.theme.links.default} 1px` : 'none'};
  border-radius: 3px;
  width: ${(props) => (props.mobileView ? '80px' : '100px')};
  margin-left: auto;
  margin-right: ${(props) => (props.mobileView ? 'auto' : '')};
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer !important;
  letter-spacing: -0.5px;
`;

const JoinCampaignCustomCheckbox = styled(CustomCheckbox)`
  min-height: 24px;
  padding-top: 2px;
`;

export const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
`;

export const SubmitSpinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ();

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 1px solid lightgrey;
  border-left: 3px solid darkgrey;

  background: transparent;
  width: ${(props) => (props.mobileView ? '10px' : '15px')};
  height: ${(props) => (props.mobileView ? '10px' : '15px')};
  border-radius: 50%;
  margin: auto;
`;

const JoinCampaignWidget = ({
  widgetProps,
  className,
  logoUrl,
  mobileView,
  anchorName,
}) => {
  const {
    title,
    name,
    email_address,
    phone,
    checkBoxes,
    submit,
    submit_success,
    submit_error
  } = widgetProps;
  const [nameState, setNameState] = useState('');
  const [emailState, setEmailState] = useState('');
  const [phoneState, setPhoneState] = useState('');
  const [willKnock, setWillKnock] = useState(false);
  const [willHost, setWillHost] = useState(false);
  const [willDigital, setWillDigital] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const { firebase } = useFirebase();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleTextInput(e, field) {
    setSuccess(false);
    switch (field) {
      case 'name':
        setNameState(e.target.value);
        break;
      case 'phone':
        setPhoneState(e.target.value);
        break;
      case 'email':
        setEmailState(e.target.value);
        break;
      default:
        break;
    }
  }

  function toggleCheckBox(e, key) {
    switch (key) {
      case 'knock':
        // console.log(willKnock);
        setWillKnock(!willKnock);
        break;
      case 'host':
        setWillHost(!willHost);
        break;
      case 'digital':
        setWillDigital(!willDigital);
        break;
      default:
        break;
    }
  }

  function clearFormValues() {
    setNameState('');
    setEmailState('');
    setPhoneState('');
    setWillDigital(false);
    setWillHost(false);
    setWillKnock(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (success) {
      clearFormValues();
      setSuccess(false);
    } else {
      setLoading(true);
      //   console.log({
      //     nameState,
      //     phoneState,
      //     emailState,
      //     willKnock,
      //     willHost,
      //     willDigital,
      //   });
      firebase
        .submitSignup({
          name: nameState,
          phone: phoneState,
          email: emailState,
          willKnock,
          willHost,
          willDigital,
        })
        .then((response, error) => {
          if (!error) {
            registerSuccess();
          } else {
            registerError();
          }
          //   console.log(response);
        });
    }
  }

  function registerSuccess() {
    setSuccess(true);
    setLoading(false);
  }

  function registerError() {
    setSuccess(false);
    setLoading(false);
    setSubmitError(true);
  }

  return (
    <JoinCampaignWidgetContainer
      mobileView={mobileView}
      className={className}
      id={anchorName}
    >
      <JoinCampaignWidgetContents>
        {mobileView && (
          <>
            <JoinCampaignTitleContainer mobileView={mobileView}>
              <JoinCampaignTitle mobileView={mobileView}>
                {title}
              </JoinCampaignTitle>
            </JoinCampaignTitleContainer>
          </>
        )}
        {!mobileView && (
          <>
            <JoinCampaignLogoContainer mobileView={mobileView}>
              <JoinCampaignLogo src={logoUrl} />
            </JoinCampaignLogoContainer>
            <JoinCampaignTitleContainer mobileView={mobileView}>
              <JoinCampaignTitle>{title}</JoinCampaignTitle>
            </JoinCampaignTitleContainer>
          </>
        )}
        <JoinCampaignForm onSubmit={handleSubmit} mobileView={mobileView}>
          <JoinCampaignFormContainer mobileView={mobileView}>
            <JoinCampaignFormItemsContainer mobileView={mobileView}>
              <JoinCampaignFieldContainer
                mobileView={mobileView}
                placeholder={name}
                key={name}
              >
                <JoinCampaignField
                  mobileView={mobileView}
                  content={nameState}
                  onChange={(e) => handleTextInput(e, 'name')}
                  type="text"
                  placeholder={name}
                  key={name}
                />
              </JoinCampaignFieldContainer>
              <JoinCampaignFieldContainer
                mobileView={mobileView}
                placeholder={email_address}
                key={email_address}
              >
                <JoinCampaignField
                  mobileView={mobileView}
                  content={emailState}
                  onChange={(e) => handleTextInput(e, 'email')}
                  type="text"
                  placeholder={email_address}
                  key={email_address}
                />
              </JoinCampaignFieldContainer>
              <JoinCampaignFieldContainer
                mobileView={mobileView}
                placeholder={phone}
                key={phone}
              >
                <JoinCampaignField
                  mobileView={mobileView}
                  content={phoneState}
                  onChange={(e) => handleTextInput(e, 'phone')}
                  type="text"
                  placeholder={phone}
                  key={phone}
                />
              </JoinCampaignFieldContainer>
            </JoinCampaignFormItemsContainer>
            <JoinCampaignFormItemsContainer mobileView={mobileView}>
              <JoinCampaignFieldContainer
                mobileView={mobileView}
                placeholder={phone}
                key={phone}
              >
                <JoinCampaignCustomCheckbox
                  labelText={checkBoxes[0].text}
                  checked={willKnock}
                  mobileView={mobileView}
                  toggleChecked={toggleCheckBox}
                  stateName={'knock'}
                />
              </JoinCampaignFieldContainer>
              <JoinCampaignFieldContainer
                mobileView={mobileView}
                key={checkBoxes[1].name}
              >
                <JoinCampaignCustomCheckbox
                  labelText={checkBoxes[1].text}
                  mobileView={mobileView}
                  checked={willHost}
                  toggleChecked={toggleCheckBox}
                  stateName={'host'}
                />
              </JoinCampaignFieldContainer>
              <JoinCampaignFieldContainer
                mobileView={mobileView}
                key={checkBoxes[2].name}
              >
                <JoinCampaignCustomCheckbox
                  labelText={checkBoxes[2].text}
                  mobileView={mobileView}
                  checked={willDigital}
                  toggleChecked={toggleCheckBox}
                  stateName={'digital'}
                />
              </JoinCampaignFieldContainer>
            </JoinCampaignFormItemsContainer>
          </JoinCampaignFormContainer>
          <JoinCampaignSubmitButtonContainer mobileView={mobileView}>
            <JoinCampaignSubmitButton mobileView={mobileView}>
              {!loading && !success && !submitError && submit}
              {loading && <SubmitSpinner mobileView={mobileView} />}
              {success && submit_success }
              {submitError && submit_error }
            </JoinCampaignSubmitButton>
          </JoinCampaignSubmitButtonContainer>
        </JoinCampaignForm>
      </JoinCampaignWidgetContents>
    </JoinCampaignWidgetContainer>
  );
};

export default JoinCampaignWidget;
