import React from 'react'
import BodyPanelContainer from '../BodyPanelContainer'
import { generateSections } from '../_generators'

export const BodyPanel = (props) => (
  <BodyPanelContainer mobileView={props.mobileView}>
    {generateSections(props.childComponents, props.mobileView)}
  </BodyPanelContainer>
)

export default BodyPanel