import React, { useMemo } from 'react'
import styled from 'styled-components'
import BodyPanelContainer from '../BodyPanelContainer'
import FullCard from './LayoutElements/FullCard';
import TightH1 from '../_Headlines/TightH1'
import Paragraphs from '../_Containers/Paragraphs'
import SquatButton from '../_common/SquatButton';
import { Link } from 'gatsby-theme-localization';
import CustomMarkdown from '../_common/CustomMarkdown';

const HighlightedPageBodyContainer = styled(BodyPanelContainer)`
  height: 155px;
`;

const HighlightedPageFullCard = styled(FullCard)`
  /* margin-bottom: 10px; */
  > div {
    padding: 0px;
  }
`

const ExternalLinkRightIfDesktop = styled.a`
  width: fit-content;
  margin-right: 0px;
  margin-left: auto;
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 18px;
    line-height: 18px;
    margin-right: auto;
    /* padding: 5px; */
    margin-right: auto;
    margin-left: -2px;
  }
  

`

const LinkRightIfDesktop = styled(Link)`
  width: fit-content;
  margin-right: 0px;
  margin-left: auto;
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    font-size: 18px;
    line-height: 18px;
    margin-right: auto;
    /* padding: 5px; */
    margin-right: auto;
    margin-left: -2px;
  }
  

`

export const HighlightedPage = ({config, headline, text, button, mobileView}) => {

  let { backgroundColor, mobileBackgroundColor, borderColor, borderWidth, borderRadius } = config;
  let internalLink = useMemo(() => button.targetPage.indexOf(':') > -1 ? false : true, [button])


  return (
    <HighlightedPageFullCard linkColors={text.linkColors} borderColor={borderColor} borderWidth={borderWidth} borderRadius={borderRadius} mobileBackgroundColor={mobileBackgroundColor} backgroundColor={backgroundColor} fitContent>
      <TightH1 {...headline}>{headline.text}</TightH1>
      <Paragraphs reduceFootprint fontSize={text.fontSize} mobileFontSize={text.mobileFontSize}>
        <CustomMarkdown>{text.text}</CustomMarkdown>
      </Paragraphs>
      { internalLink ? (
        <LinkRightIfDesktop to={button.targetPage}><SquatButton {...button}>{button.text}</SquatButton></LinkRightIfDesktop>
      ) : (
        <ExternalLinkRightIfDesktop target={"_blank"} href={button.targetPage}><SquatButton {...button}>{button.text}</SquatButton></ExternalLinkRightIfDesktop>
      )}
      
    </HighlightedPageFullCard>
  )

}

export const HighlightedPagePanel = (props) => {

  return (
    <HighlightedPageBodyContainer>
      <HighlightedPage {...props} />
    </HighlightedPageBodyContainer>
  )


}

export default HighlightedPagePanel