import React from 'react'
import styled from 'styled-components'
import BodyPanelContainer from '../BodyPanelContainer';
import GalleryLauncher from './GalleryLauncher';


const GalleryLauncherBodyContainer = styled(BodyPanelContainer)`
  height: 155px;
`;


const GalleryLaunchBanner = (props) => (
  <GalleryLauncherBodyContainer>
    <GalleryLauncher {...props}/>
  </GalleryLauncherBodyContainer>
)

export default GalleryLaunchBanner