import styled from 'styled-components'

export const PanelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  ${p => p.additionalTopMargin ? `
    margin-top: ${p.additionalTopMargin}px;
  `:``}
  margin-bottom: 0px;
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }

`

export default PanelRow