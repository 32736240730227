import React from 'react'
import {PanelRow} from './PanelRow'
import generateSections from '../../_generators/generateSections'

export const PanelRowWithChildren = ({childComponents, config, mobileView}) => {

  const { additionalTopMargin, noBottomMargin } = config;

  return (
  <PanelRow noBottomMargin={noBottomMargin} additionalTopMargin={additionalTopMargin}>
    {generateSections(childComponents, mobileView)}
  </PanelRow>
)}

export default PanelRowWithChildren