import React from 'react'
import styled from 'styled-components'
import BodyPanelContainer from '../BodyPanelContainer'

const VideoPlayerBodyPanelContainer = styled(BodyPanelContainer)`
    /* padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px; */
`

const VideoPlayerContainer = styled.div`
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
`

const VideoPlayerIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`

const VideoPlayer = ({embedUrl, mobileView}) => {
    return (
        <VideoPlayerBodyPanelContainer>
            <VideoPlayerContainer>
                <VideoPlayerIframe src={embedUrl} />
            </VideoPlayerContainer>
        </VideoPlayerBodyPanelContainer>

    )
}

export default VideoPlayer;