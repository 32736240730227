import React, { useMemo } from 'react'
import styled from 'styled-components'
import BodyPanelContainer from '../../BodyPanelContainer'
import TextSection from './TextSection'
import FileResource from './FileResource'
import LinkResource from './LinkResource'
import Headline from '../Headline';
import GalleryLauncherResource from './GalleryLauncherResource'



const ResourcesSectionBodyContainer = styled(BodyPanelContainer)``;

const ResourcesSectionContainer = styled.div`
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor] || 'white'};
  color: ${(props) => props.theme.colors[props.textColor] || 'black'};
  padding-left: 10px;
  /* padding-top: 1px; */
  padding-bottom: 5px;
  padding-right: 10px;

  div {
    h2 {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

`;

const ResourcesContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;

`


function renderResources(resources, mobileView) {
  const output = [];


  resources.forEach((resource, i) => {

    output.push((
      <React.Fragment key={'resource'+i}>
        {
          {
            'text': <TextSection mobileView={mobileView} {...resource.params} />,
            'downloadable': <FileResource key={'fileresource'+i} {...resource.params} />,
            'link': <LinkResource key={'linkresource'+i} {...resource.params} />,
            'GalleryLauncher': (
              <GalleryLauncherResource key={'gallerylauncher'+i} {...resource.params} />
            )
          }[resource.type]
        }
      </React.Fragment>
    ))
  })

  return output
}


const ResourcesSection = ({
  headline,
  anchorName,
  textColor,
  backgroundColor,
  hideHeadlineArrow,
  headlineShadowColor,
  headlineColor,
  headlineLevel = 2,
  resources,
  mobileView,
  unifiedBody
}) => {

  const renderedResources = useMemo(() => {
    return renderResources(resources, mobileView)
  }, [resources, mobileView])

  return (
    <ResourcesSectionBodyContainer mobileView={mobileView} id={anchorName} unifiedBody={unifiedBody}>
      <ResourcesSectionContainer
        mobileView={mobileView}
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        <Headline
          hideTopArrow={hideHeadlineArrow}
          mobileView={mobileView}
          h={headlineLevel}
          color={headlineColor}
          shadowColor={headlineShadowColor}
          hideArrow={true}
        >
          {headline}
        </Headline>
        <ResourcesContainer>
          {renderedResources}
        </ResourcesContainer>
      </ResourcesSectionContainer>
    </ResourcesSectionBodyContainer>
  );
};

export default ResourcesSection;
